import { SVGProps } from "react";

const UsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="M14 15.5A6.75 6.75 0 1 0 14 2a6.75 6.75 0 0 0 0 13.5Zm6 1.5h-2.583a8.16 8.16 0 0 1-6.834 0H8a6 6 0 0 0-6 6v.75A2.251 2.251 0 0 0 4.25 26h19.5A2.251 2.251 0 0 0 26 23.75V23a6 6 0 0 0-6-6Z"
    />
  </svg>
);

export default UsersIcon;
