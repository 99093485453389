/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { wrap } from "popmotion";

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

interface IAlbumProps {
  images: string[];
  defaultIndex: number;
  onChangeIndex: (index: number) => void;
}

const Album: React.FC<IAlbumProps> = ({
  images,
  defaultIndex,
  onChangeIndex,
}) => {
  const [isZooming, setZooming] = useState(false);
  const [scale, setScale] = useState(1);
  const [[page, direction], setPage] = useState([defaultIndex, 0]);
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (defaultIndex !== imageIndex) {
      onChangeIndex(imageIndex);
    }
    setScale(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex]);

  return (
    <AnimatePresence initial={false} custom={direction}>
      <motion.div
        key={page}
        // src={images[imageIndex]}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
        drag={!isZooming && scale <= 1 ? "x" : undefined}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(_, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
          }
        }}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TransformWrapper
          alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
          panning={{ disabled: scale === 1, velocityDisabled: true }}
          minScale={1}
          initialScale={1}
          onZoomStop={(ref) => {
            setZooming(false);
            setScale(ref.state.scale);
          }}
          onZoomStart={(ref) => {
            setZooming(true);
          }}
        >
          <TransformComponent>
            <img src={images[imageIndex]} />
          </TransformComponent>
        </TransformWrapper>
      </motion.div>
    </AnimatePresence>
  );
};

export default Album;
