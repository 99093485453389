import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { store, type TRootState } from "../store";
import { refreshTokens } from "../auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: async (headers, { getState }) => {
    const { refreshToken } = (getState() as TRootState).auth;

    if (refreshToken) {
      await store.dispatch(refreshTokens(refreshToken));

      const { accessToken } = (getState() as TRootState).auth;
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
    }

    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: ["Client", "Construction", "User", "Document", "DocumentFile"],
  endpoints: () => ({}),
});
