import { api } from "./index";
import { TAG_LIST_SYMBOL } from "../../constants/redux";
import type {
  IPaginatedReponse,
  IPaginatedRequestQueryParams,
} from "../../types/api";
import type { IDocument, IDocumentFile } from "../../types/document";

export interface IGetDocumentsQueryFilters {
  ["filters[id]"]?: string;
  ["filters[warehouse_id]"]?: string;
  ["filters[client_id]"]?: string;
  ["filters[document_number]"]?: string;
  ["filters[construction_id]"]?: string;
  ["filters[document_type]"]?: string;
  ["filters[document_date]"]?: string;
  ["filters[document_date_from]"]?: string;
  ["filters[document_date_to]"]?: string;
  ["filters[starts_with]"]?: string;
}

type TGetDocumentsQueryParams = IPaginatedRequestQueryParams &
  IGetDocumentsQueryFilters;

export const documentsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query<
      IPaginatedReponse<IDocument>,
      TGetDocumentsQueryParams
    >({
      query: (params) =>
        `/documents?${new URLSearchParams(
          params as unknown as Record<string, string>
        ).toString()}`,
      providesTags: (result) =>
        result
          ? [
              { id: TAG_LIST_SYMBOL, type: "Document" },
              ...result.data.map((doc) => ({
                id: doc.id,
                type: "Document" as const,
              })),
            ]
          : [],
    }),
    deleteDocument: builder.mutation<void, IDocument["id"]>({
      query: (id) => ({
        url: `/documents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        error
          ? []
          : [
              { type: "Document", id: TAG_LIST_SYMBOL },
              { type: "Document", id },
            ],
    }),
    uploadDocumentFile: builder.mutation<
      void,
      { id: IDocument["id"]; filename: string; data: Blob }
    >({
      query: ({ id, filename, data }) => ({
        method: "PUT",
        url: `/documents/${id}/files?filename=${encodeURIComponent(filename)}`,
        body: data,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: "DocumentFile", id: TAG_LIST_SYMBOL }],
    }),
    getDocumentFiles: builder.query<
      IPaginatedReponse<IDocumentFile>,
      { id: IDocument["id"]; params: IPaginatedRequestQueryParams }
    >({
      query: ({ id, params }) =>
        `/documents/${id}/files?${new URLSearchParams(
          params as unknown as Record<string, string>
        ).toString()}`,
      providesTags: (result) =>
        result
          ? [
              { id: TAG_LIST_SYMBOL, type: "DocumentFile" },
              ...result.data.map((doc) => ({
                id: doc.id,
                type: "DocumentFile" as const,
              })),
            ]
          : [],
      transformResponse: (result: IPaginatedReponse<IDocumentFile>) => ({
        ...result,
        data: result.data.sort((a, b) => a.filename.localeCompare(b.filename)),
      }),
    }),
    deleteDocumentFile: builder.mutation<
      void,
      { docId: IDocument["id"]; fileId: IDocumentFile["id"] }
    >({
      query: ({ docId, fileId }) => ({
        url: `/documents/${docId}/files/${fileId}`,
        method: "DELETE",
      }),
      // invalidatesTags: (_, error, { fileId: id }) =>
      //   error
      //     ? []
      //     : [
      //         { type: "DocumentFile", id: TAG_LIST_SYMBOL },
      //         { type: "DocumentFile", id },
      //       ],
      async onQueryStarted({ docId, fileId }, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            documentsEndpoints.util.updateQueryData(
              "getDocumentFiles",
              { id: docId, params: { page: 1, perPage: 100 } },
              (draft) => {
                Object.assign(draft, {
                  data: draft.data.filter((file) => file.id !== fileId),
                });
              }
            )
          );

          await queryFulfilled;
        } catch {}
      },
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useDeleteDocumentMutation,
  useUploadDocumentFileMutation,
  useGetDocumentFilesQuery,
  useDeleteDocumentFileMutation,
} = documentsEndpoints;
