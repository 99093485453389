import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, type SubmitHandler } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";

import {
  useCreateMessageMutation,
  useDeleteMessageMutation,
} from "../../redux/api/messageEndpoints";
import useErrorMsg from "../../hooks/useErrorMsg";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import type { IMessage } from "../../types/message";

interface IMessageFormValues extends Omit<IMessage, "createdAt"> {}

interface IMessageFormProps {
  exisitingMessage?: IMessage;
  onClose: () => void;
  isFetching?: boolean;
}

const MessageForm: FC<IMessageFormProps> = ({
  exisitingMessage,
  onClose,
  isFetching,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IMessageFormValues>({
    defaultValues: {
      title: "",
      message: "",
    },
  });

  const [createMessage, createState] = useCreateMessageMutation();
  const [deleteMessage, deleteState] = useDeleteMessageMutation();

  const isLoading = createState.isLoading || deleteState.isLoading;
  const error = createState.error || deleteState.error;
  const errorMsg = useErrorMsg(error);

  const onSubmit: SubmitHandler<IMessageFormValues> = async (values) => {
    if (exisitingMessage) {
      await deleteMessage();
      reset();
    } else {
      await createMessage(values);
    }

    onClose();
  };

  useEffect(() => {
    if (exisitingMessage) {
      setValue("title", exisitingMessage.title);
      setValue("message", exisitingMessage.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exisitingMessage]);

  return (
    <Box as="form" w="full" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4">
        {errorMsg && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{errorMsg}</AlertTitle>
          </Alert>
        )}

        <FormControl isInvalid={!!errors.title}>
          <FormLabel>{t("message.form.title.label")}</FormLabel>
          <Input
            size="lg"
            placeholder={t("message.form.title.placeholder") as string}
            // {...(exisitingMessage ? { value: exisitingMessage.title } : {})}
            {...register(
              "title",
              exisitingMessage
                ? { disabled: true, value: "22" }
                : {
                    required: true,
                    maxLength: 100,
                  }
            )}
          />

          {errors.title && (
            <FormErrorMessage>
              {t(`message.form.title.${errors.title.type || "required"}`)}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.message}>
          <FormLabel>{t("message.form.message.label")}</FormLabel>
          <Textarea
            size="lg"
            placeholder={t("message.form.message.placeholder") as string}
            focusBorderColor="secondary.500"
            errorBorderColor="error"
            height="184px"
            resize="none"
            borderWidth="1px"
            // {...(exisitingMessage ? { value: exisitingMessage.message } : {})}
            {...register(
              "message",
              exisitingMessage
                ? { disabled: true }
                : {
                    required: true,
                    maxLength: 500,
                  }
            )}
          />

          {errors.message && (
            <FormErrorMessage>
              {t(`message.form.message.${errors.message.type || "required"}`)}
            </FormErrorMessage>
          )}
        </FormControl>
      </VStack>

      <HStack spacing="2" mt="6">
        <Button
          type="submit"
          colorScheme="secondary"
          variant="outline"
          isLoading={isLoading || isFetching}
          rightIcon={<Icon as={CalendarIcon} height="4" width="auto" ml="3" />}
        >
          {exisitingMessage ? "Usuń" : "Opublikuj"}
        </Button>
        <Button
          type="button"
          colorScheme="secondary"
          variant="ghost"
          fontWeight="medium"
          onClick={() => onClose()}
        >
          Anuluj
        </Button>
      </HStack>
    </Box>
  );
};

export default MessageForm;
