import { FC } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import {
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import { Worker } from "@react-pdf-viewer/core";

import { theme } from "./app/theme";
import { store } from "./redux/store";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import DashboardLayout from "./components/layout/DashboardLayout";
import Clients from "./components/pages/Clients";
import Docs from "./components/pages/Docs";
import Users from "./components/pages/Users";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AuthProvider from "./components/providers/AuthProvider";
import Constructions from "./components/pages/Constructions";
import ConstructionDetails from "./components/pages/ConstructionDetails";
import DocDetails from "./components/pages/DocDetails";

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Navigate to="dashboard" />} />

      <Route path="login" element={<Login />} />

      <Route
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="clients" element={<Clients />} />
        <Route path="clients/:clientId" element={<Constructions />} />
        <Route
          path="clients/:clientId/constructions/:constructionId"
          element={<ConstructionDetails />}
        />
        <Route
          path="clients/:clientId/constructions/:constructionId/docs/:documentId"
          element={<DocDetails />}
        />

        <Route path="docs" element={<Docs />} />
        <Route path="docs/:documentId" element={<DocDetails />} />
        <Route path="users" element={<Users />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);

const App: FC = () => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <RouterProvider router={router} />
      </Worker>
    </ChakraProvider>

    <AuthProvider />
  </Provider>
);

export default App;
