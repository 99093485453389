import { Grid } from "@chakra-ui/react";

import ClientsIllustration from "../../assets/illustrations/ClientsIllustration";
import FilesIllustration from "../../assets/illustrations/FilesIllustration";
import FolderIllustration from "../../assets/illustrations/FolderIllustration";
import PenIllustration from "../../assets/illustrations/PenIllustration";
import UsersIllustration from "../../assets/illustrations/UsersIllustration";
import {
  ADD_CLIENT_HASH,
  ADD_CONSTRUCTION_HASH,
  ADD_USER_HASH,
} from "../../constants/hashes";
import SubjectCard from "./SubjectCard";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";
import { UserRole } from "../../types/user";
import type { ISubjectCard } from "../../types/subjectCard";

const SubjectCards = () => {
  const {
    roles: [role],
    clients,
    constructions,
    access,
  } = useAccessTokenPayload()!;

  const cards: ISubjectCard[] = [
    ...(role === UserRole.Manager && clients.length >= 1
      ? [
          {
            icon: <ClientsIllustration />,
            name: clients.length > 1 ? "Kontrahenci i obiekty" : "Obiekty",
            buttons:
              clients.length > 1
                ? [
                    {
                      label: "Kontrahenci",
                      href: "/clients",
                      isPrimary: true,
                    },
                  ]
                : [
                    {
                      label: "Obiekty",
                      href: `/clients/${clients[0].id}`,
                      isPrimary: true,
                    },
                  ],
          },
        ]
      : role === UserRole.Head && clients.length > 0 && constructions.length > 1
      ? [
          {
            icon: <ClientsIllustration />,
            name: "Obiekty",
            buttons: [
              {
                label: "Obiekty",
                href: `/clients/${clients[0].id}`,
                isPrimary: true,
              },
            ],
          },
        ]
      : role === UserRole.SuperAdmin ||
        role === UserRole.Admin ||
        role === UserRole.Employee
      ? [
          {
            icon: <ClientsIllustration />,
            name: "Kontrahenci i obiekty",
            buttons: [
              {
                label: "Kontrahenci",
                href: "/clients",
                isPrimary: true,
              },
              ...(role !== UserRole.Employee
                ? [
                    {
                      label: "Dodaj Kontrahenta",
                      href: `/clients#${ADD_CLIENT_HASH}`,
                      isAdd: true,
                    },
                    {
                      label: "Dodaj Obiekt",
                      href: `/clients#${ADD_CONSTRUCTION_HASH}`,
                      isAdd: true,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...((role !== UserRole.Head && role !== UserRole.Foreman) ||
    (role === UserRole.Head &&
      access?.some((access) => access.type === "invoice" && access.granted))
      ? [
          {
            icon: <FilesIllustration />,
            name: "Faktury",
            buttons: [
              {
                label: "Sprzedaży",
                href: "/docs?type=InvoiceWithAttachment",
                isPrimary: true,
              },
              {
                label: "Korygujące",
                href: "/docs?type=InvoiceCorrection",
                isPrimary: true,
              },
              // {
              //   label: "Eksportowe",
              //   href: "/docs?type=ExportInvoice",
              //   isPrimary: true,
              // },
            ],
          },
        ]
      : []),
    ...(role !== UserRole.Head ||
    (role === UserRole.Head &&
      access?.some((access) => access.type === "protocol" && access.granted))
      ? [
          {
            icon: <PenIllustration />,
            name: "Protokoły",
            buttons: [
              {
                label: "Wydania",
                href: "/docs?type=ReleaseProtocol",
                isPrimary: true,
              },
              {
                label: "Likwidacji",
                href: "/docs?type=LiquidationProtocol",
                isPrimary: true,
              },
              {
                label: "Zwrotu",
                href: "/docs?type=ReturnProtocol",
                isPrimary: true,
              },
            ],
          },
        ]
      : []),
    ...(role !== UserRole.Head ||
    (role === UserRole.Head &&
      access?.some((access) => access.type === "possession" && access.granted))
      ? [
          {
            icon: <FolderIllustration />,
            name: "Stany posiadania",
            buttons: [
              {
                label: "Zobacz listę",
                href: "/docs?type=PossessionState",
                isPrimary: true,
              },
            ],
          },
        ]
      : []),
    ...(role === UserRole.SuperAdmin || role === UserRole.Admin
      ? [
          {
            icon: <UsersIllustration />,
            name: "Użytkownicy",
            buttons: [
              {
                label: "Użytkownicy",
                href: "/users",
                isPrimary: true,
              },
              {
                label: "Dodaj Użytkownika",
                href: `/users#${ADD_USER_HASH}`,
                isAdd: true,
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <Grid templateColumns={{ base: "1fr", xl: "repeat(2, 1fr)" }} gap="5">
      {cards.map((card, i) => (
        <SubjectCard key={i} card={card} />
      ))}
    </Grid>
  );
};

export default SubjectCards;
