import { FC } from "react";
import { Box, Center, Flex, Img, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";

import LoginForm from "../auth/LoginForm";
import { useAppSelector } from "../../redux/store";
import { selectAccessToken } from "../../redux/auth/authSlice";

const Login: FC = () => {
  const { t } = useTranslation();
  const isUserLogged = useAppSelector(selectAccessToken);
  const [searchParams] = useSearchParams();

  return isUserLogged ? (
    <Navigate to={searchParams.get("returnUrl") || "/"} />
  ) : (
    <Flex w="full" h={{ base: "auto", lg: "100vh" }}>
      <Box flex="1" display={{ base: "none", lg: "block" }}>
        <Img
          w="full"
          h="full"
          objectFit="cover"
          objectPosition="center"
          src="assets/bg.png"
          srcSet="assets/bg@2x.png 2x,
             assets/bg@3x.png 3x"
        />
      </Box>

      <Center flex="1" flexDir="column" p="8">
        <Img
          src="assets/full-logo.png"
          srcSet="assets/full-logo@2x.png 2x,
             assets/full-logo@3x.png 3x"
        />

        <Text
          fontSize="2xl"
          textAlign="center"
          maxW="md"
          dangerouslySetInnerHTML={{ __html: t("login.heading") as string }}
          css={`
            span {
              color: var(--chakra-colors-primary-500);
            }
          `}
          mt="20"
          mb="10"
        />

        <LoginForm />

        <Link
          href="mailto:pomoc@novarent.pl?subject=Przypomnienie hasła - Strefa Partnera Novarent&body=Proszę o nowe hasło dla loginu: tu wpisz swój login"
          mt="8"
          mb="9"
          color="secondary"
          textDecoration="underline"
          fontSize="15px"
        >
          {t("login.forgot")}
        </Link>

        {/* <Text
          textAlign="center"
          whiteSpace="pre-wrap"
          color="secondary"
          fontSize="sm"
          dangerouslySetInnerHTML={{ __html: t("login.footer") as string }}
        /> */}
        <Text
          textAlign="center"
          whiteSpace="pre-wrap"
          color="secondary"
          fontSize="sm"
        >
          <Text as="span" fontWeight="semibold">
            Novarent Sp. z o. o.
          </Text>
          <br />
          E-mail:{" "}
          <Link href="mailto:biuro@novarent.pl" textDecoration="underline">
            biuro@novarent.pl
          </Link>
          <br />
          Dział handlowy: <Link href="tel:+48669250450">+48 669 250 450</Link>
          <br />
          Dział techniczny: <Link href="tel:+48730427195">+48 730 427 195</Link>
          <br />
          Magazyn, obsługa platformy:{" "}
          <Link href="tel:+48570640360">+48 570 640 360</Link>
        </Text>

        <Text color="secondary" fontSize="sm" mt="6">
          Realizacja:{" "}
          <Link
            href="https://honki.pl/"
            target="_blank"
            textDecoration="underline"
          >
            HONKI
          </Link>
        </Text>
      </Center>
    </Flex>
  );
};

export default Login;
