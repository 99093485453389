import { FC, useEffect, useState } from "react";
import {
  AspectRatio,
  Box,
  Center,
  Fade,
  HStack,
  Img,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  VStack,
} from "@chakra-ui/react";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin, type RenderZoomProps } from "@react-pdf-viewer/zoom";

import type { IDocument } from "../../types/document";
import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import DocPreviewMenu from "../docs/DocPreviewMenu";
import PreviewIcon from "../../assets/icons/PreviewIcon";
import useDesktopQuery from "../../hooks/useDesktopQuery";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

interface IPDFViewerModal extends Omit<ModalProps, "children"> {
  doc: IDocument;
}

const PDFViewerModal: FC<IPDFViewerModal> = ({
  doc,
  onClose,
  isOpen,
  ...rest
}) => {
  const [showPictogram, setShowPictogram] = useState(true);
  const isDesktop = useDesktopQuery();
  const getDocFileUrl = useGetDocFileUrl();

  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;

  useEffect(() => {
    setShowPictogram(true);

    const timeout = setTimeout(() => setShowPictogram(false), 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, doc.id]);

  return (
    <Modal
      onClose={onClose}
      size="full"
      // blockScrollOnMount={false}
      isOpen={isOpen}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        p="0"
        bg="transparent"
        boxShadow="none"
        position="relative"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            onClose();
          }
        }}
      >
        <Box position="absolute" top={{ base: "4", lg: "10" }} right="0">
          <VStack spacing="10" alignItems="flex-end">
            <HStack spacing="10">
              <DocPreviewMenu
                doc={doc}
                actions={["download", "print", "delete"]}
                onAction={(action) => {
                  if (action === "delete") {
                    onClose();
                  }
                }}
              />

              <Center
                as="button"
                w="10"
                h="10"
                bg="white"
                boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
                onClick={() => onClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.799"
                  height="19.799"
                  viewBox="0 0 19.799 19.799"
                >
                  <path
                    transform="rotate(45 -.25 .604)"
                    fill="none"
                    stroke="currentColor"
                    d="M0 0h27"
                  />
                  <path
                    transform="rotate(135 9.65 4.204)"
                    fill="none"
                    stroke="currentColor"
                    d="M0 0h27"
                  />
                </svg>
              </Center>
            </HStack>

            {isDesktop && (
              <Zoom>
                {({ scale, onZoom }: RenderZoomProps) => (
                  <VStack spacing="2.5">
                    <Center
                      as="button"
                      w="10"
                      h="10"
                      bg="white"
                      boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
                      onClick={() => onZoom(scale + 0.15)}
                    >
                      <PreviewIcon />
                    </Center>
                    <Center
                      as="button"
                      w="10"
                      h="10"
                      bg="white"
                      boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
                      onClick={() => onZoom(scale - 0.15)}
                    >
                      <PreviewIcon zoomOut />
                    </Center>
                  </VStack>
                )}
              </Zoom>
            )}
          </VStack>
        </Box>

        {isDesktop ? (
          <AspectRatio
            w="calc(100% - 8rem)"
            marginX="auto"
            maxW="6xl"
            ratio={572 / 786}
            bg="#efefef"
            border="solid 1px #c9c9c9"
            mt="144px"
            mb="8"
            overflow="hidden"
          >
            <Box>
              <Viewer
                fileUrl={getDocFileUrl(doc.id)}
                defaultScale={SpecialZoomLevel.PageFit}
                plugins={[zoomPluginInstance]}
              />
            </Box>
          </AspectRatio>
        ) : (
          <Box mt="80px">
            <TransformWrapper>
              <TransformComponent
                wrapperStyle={{ height: "calc(100vh - 64px - 2rem)" }}
              >
                <Viewer
                  fileUrl={getDocFileUrl(doc.id)}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </TransformComponent>
            </TransformWrapper>
          </Box>
        )}

        {!isDesktop && (
          <Box
            position="absolute"
            left="50%"
            top="50%"
            transform="auto"
            translateX="-50%"
            translateY="-50%"
            zIndex={2}
            pointerEvents="none"
          >
            <Fade in={showPictogram}>
              <Img
                src="assets/zoom.png"
                srcSet="assets/zoom@2x.png 2x, assets/zoom@3x.png 3x"
              />
            </Fade>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PDFViewerModal;
