import { FC, SVGProps, useState } from "react";

const XIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const [isHover, setHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20.023"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill={isHover ? "#002765" : "#d9d9d9"} />
      <path
        transform="rotate(45 -3.72 8.682)"
        fill="none"
        stroke={isHover ? "#d9d9d9" : "#002765"}
        d="M0 0h14"
      />
      <path
        transform="rotate(135 6.403 5.683)"
        fill="none"
        stroke={isHover ? "#d9d9d9" : "#002765"}
        d="M0 0h14"
      />
    </svg>
  );
};

export default XIcon;
