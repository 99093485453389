import { useEffect } from "react";
import { useDispatch } from "react-redux";
import jwtDecode, { type JwtPayload } from "jwt-decode";

import { logout, selectAccessToken } from "../redux/auth/authSlice";
import { useAppSelector } from "../redux/store";
import { selectRefreshToken } from "../redux/auth/authSlice";
import { REFRESH_TOKEN_KEY } from "../constants/storage";

const useAutoLogout = () => {
  const dispatch = useDispatch();
  const refreshToken = useAppSelector(selectRefreshToken);
  const accessToken = useAppSelector(selectAccessToken);

  useEffect(() => {
    if (refreshToken && accessToken) {
      try {
        const { exp } = jwtDecode(accessToken) as JwtPayload;
        const timeout = setTimeout(() => {
          dispatch(logout());
          localStorage.removeItem(REFRESH_TOKEN_KEY);
        }, exp! * 1000 - Date.now());

        return () => {
          clearTimeout(timeout);
        };
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken, accessToken]);
};

export default useAutoLogout;
