import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, HStack } from "@chakra-ui/react";

import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import type { IDocument } from "../../types/document";

interface IDocumentRowActionsProps {
  doc: IDocument;
  pathPrefix?: string;
}

const DocumentRowActions: FC<IDocumentRowActionsProps> = ({
  doc: { id },
  pathPrefix,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const getDocFileUrl = useGetDocFileUrl();

  return (
    <HStack spacing={4} justifyContent="flex-end">
      <Button
        as="a"
        href={getDocFileUrl(id)}
        target="_blank"
        variant="outline"
        colorScheme="secondary"
        size="sm"
      >
        Pobierz
      </Button>
      <Button
        variant="outline"
        colorScheme="secondary"
        onClick={() =>
          navigate(`${pathPrefix || ""}/docs/${id}`, {
            state: { prevLocation: location },
          })
        }
        size="sm"
      >
        Przejdź
      </Button>
    </HStack>
  );
};

export default DocumentRowActions;
