import { FC, useMemo } from "react";
import { Box, Button, Text } from "@chakra-ui/react";

import type { IFilterAbcItem } from "../../types/filters";
import Filters from "../common/Filters";
import { ADD_CLIENT_HASH, ADD_CONSTRUCTION_HASH } from "../../constants/hashes";
import { useGetClientsQuery } from "../../redux/api/clientsEndpoints";
import useHash from "../../hooks/useHash";
import Dialog from "../common/Dialog";
import EditClientForm from "../common/EditClientForm";
import ListingPage from "../layout/ListingPage";
import usePageParams from "../../hooks/usePageParams";
import ListingPagination from "../common/ListingPagination";
import { getPageCountFromMeta } from "../../utils/pagination";
import useSortingParams from "../../hooks/useSortingParams";
import LoadingTable from "../common/table/LoadingTable";
import EditConstructionForm from "../common/EditConstructionForm";
import useFiltersParams from "../../hooks/useFiltersParams";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { IClient } from "../../types/client";
import ClientRowActions from "../common/ClientRowActions";
import GenericTable from "../common/table/GenericTable";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";
import { UserRole } from "../../types/user";

type ClientsRestFilters = {
  starts_with: IFilterAbcItem;
};

const columnHelper = createColumnHelper<IClient>();

const Clients: FC = () => {
  const {
    roles: [role],
  } = useAccessTokenPayload()!;
  const canUserAddObjects =
    role === UserRole.SuperAdmin || role === UserRole.Admin;

  const { hash, setHash, removeHash } = useHash();
  const isAddClientModalOpen = hash === ADD_CLIENT_HASH;
  const isAddConstructionModalOpen = hash === ADD_CONSTRUCTION_HASH;

  const restFilters = useMemo<ClientsRestFilters>(
    () => ({
      starts_with: { type: "abc" },
    }),
    []
  );
  const filters = useMemo(
    () => ({
      ...restFilters,
    }),
    [restFilters]
  );
  const { values: filterValues, onChange: handleFiltersChange } =
    useFiltersParams(filters);

  const { page, perPage } = usePageParams();
  const { sortBy, sortOrder, sortingState, setSortingState } =
    useSortingParams();

  const { data: getClientsData, isFetching } = useGetClientsQuery({
    page,
    perPage,
    ...(sortBy && sortOrder
      ? {
          sortBy,
          sortOrder,
        }
      : {
          sortBy: "id",
          sortOrder: "desc",
        }),
    ...(filterValues.starts_with
      ? { "filters[starts_with]": filterValues.starts_with }
      : {}),
  });
  const pageCount = getPageCountFromMeta(getClientsData?.meta);

  const columns = useMemo<ColumnDef<IClient, any>[]>(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: "id",
        header: () => "ID",
        cell: (info) => <Text>{info.renderValue()}</Text>,
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        header: () => "Nazwa kontrahenta",
        cell: (info) => <Text as="b">{info.renderValue()}</Text>,
      }),
      columnHelper.accessor((row) => row.id, {
        id: "actions",
        header: () => "",
        cell: ({ row }) => (
          <ClientRowActions client={row.original} canEdit={canUserAddObjects} />
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canUserAddObjects]
  );

  return (
    <>
      <ListingPage
        isFetching={!!getClientsData && isFetching}
        filters={
          <Filters
            filters={restFilters}
            values={filterValues}
            // @ts-ignore
            onChange={handleFiltersChange}
          />
        }
        actions={
          canUserAddObjects ? (
            <>
              <Button
                colorScheme="gray"
                onClick={() => {
                  setHash(ADD_CLIENT_HASH);
                }}
              >
                Dodaj kontrahenta
              </Button>
              <Button
                colorScheme="gray"
                onClick={() => {
                  setHash(ADD_CONSTRUCTION_HASH);
                }}
              >
                Dodaj obiekt
              </Button>
            </>
          ) : undefined
        }
      >
        <Box flex="1" w="full" px={{ base: "0", lg: "4" }}>
          {getClientsData?.data ? (
            <GenericTable
              data={getClientsData.data}
              columns={columns}
              sortingState={sortingState}
              setSortingState={setSortingState}
              emptyStateText="List kontrahentów jest pusta"
            />
          ) : (
            <LoadingTable />
          )}
        </Box>

        <ListingPagination pageCount={pageCount} />
      </ListingPage>

      {canUserAddObjects && (
        <>
          <Dialog
            isOpen={isAddClientModalOpen}
            onClose={() => removeHash()}
            title="Dodaj kontrahenta"
          >
            <EditClientForm onClose={() => removeHash()} />
          </Dialog>

          <Dialog
            isOpen={isAddConstructionModalOpen}
            onClose={() => removeHash()}
            title="Dodaj obiekt"
          >
            <EditConstructionForm onClose={() => removeHash()} />
          </Dialog>
        </>
      )}
    </>
  );
};

export default Clients;
