import { FC } from "react";

const CheckboxIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="m21.636 9-10.75 10.75L6 14.864"
      data-name="Icon feather-check"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      fill="none"
      transform="translate(-1.818 -2)"
    />
  </svg>
);

export default CheckboxIcon;
