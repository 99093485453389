import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { useGetClientQuery } from "../../redux/api/clientsEndpoints";
import { useGetConstructionQuery } from "../../redux/api/constructionsEndpoints";
import Docs from "./Docs";

const ConstructionDetails: FC = () => {
  const navigate = useNavigate();
  const { clientId, constructionId } = useParams();

  const { data: client } = useGetClientQuery(clientId!);
  const { data: construction, error } = useGetConstructionQuery(
    constructionId!
  );

  useEffect(() => {
    if (construction && !construction.id) {
      navigate(client?.id ? `/clients/${client.id}` : "/clients");
    } else if (error) {
      console.error(error);
      navigate("..");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [construction?.id, error]);

  return (
    <>
      {client?.id && construction?.id ? (
        <Docs
          goBack={{
            to: `/clients/${clientId}`,
            label: `${client.name || client.id} / ${
              construction.name || construction.id
            }`,
          }}
          pathPrefix={`/clients/${clientId}/constructions/${construction.id}`}
          removeNames
          staticFilters={{ "filters[construction_id]": construction.id }}
        />
      ) : (
        <Spinner size="lg" display="block" mx="auto" my="8" />
      )}
    </>
  );
};

export default ConstructionDetails;
