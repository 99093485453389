import { SVGProps } from "react";

const ClientsIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" {...props}>
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#002765"
        d="M50.653 27.882V10H6v80.5h87.209V27.882H50.653ZM23.861 81.549h-8.93V72.6h8.931l-.001 8.949Zm0-17.882h-8.93v-8.952h8.931l-.001 8.952Zm0-17.882h-8.93v-8.952h8.931l-.001 8.952Zm0-17.9h-8.93v-8.954h8.931l-.001 8.954Zm17.864 53.664h-8.933V72.6h8.933v8.949Zm0-17.882h-8.933v-8.952h8.933v8.952Zm0-17.882h-8.933v-8.952h8.933v8.952Zm0-17.9h-8.933v-8.954h8.933v8.954Zm42.553 53.664H50.653V72.6h8.931v-8.933h-8.931v-8.952h8.931v-8.951h-8.931v-8.931h33.625v44.716Zm-7.882-35.764h-8.931v8.951h8.931v-8.951Zm0 17.882h-8.931v8.951h8.931v-8.951Z"
      />
    </svg>
  );
};

export default ClientsIllustration;
