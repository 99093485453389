import { SVGProps } from "react";

const MenuIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      {...props}
    >
      <g fillRule="evenodd">
        <path d="M6 9h24v1H6zM6 17.5h24v1H6zM6 26h24v1H6z" />
      </g>
    </svg>
  );
};

export default MenuIcon;
