import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const colors = {
  error: "#ff0000",
  white: "#FFFFFF",
  primary: {
    500: "#ED7D1B",
    400: "#F89138",
    200: "#F3DDCC",
  },
  secondary: {
    500: "#002765",
    400: "#164997",
    100: "#EBEEF3",
  },
  gray: {
    400: "#DDDDDD",
    300: "#DDDDDD",
    200: "#DDDDDD",
    100: "#E8E8E8",
    50: "#F2F2F2",
  },
};

export const theme = extendTheme({
  config,
  styles: {
    global: {
      "html, body": {
        height: "100%",
      },
      body: {
        bg: "gray.50",
        color: "secondary.500",
      },
      "body.no-scroll": {
        overflow: "hidden",
      },
      "#root": {
        width: "100%",
        minHeight: "100vh",
      },
      "*": {
        "-webkit-tap-highlight-color": "transparent",
      },
      ".pagination": {
        display: "inline-flex",
        alignItems: "center",
        listStyle: "none",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "gray.400",
        rounded: "lg",
        borderRightWidth: "0px",
        roundedRight: "0px",
      },
      ".pagination > li > a": {
        minW: "8",
        h: "8",
        px: "1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        _hover: {
          bg: "gray.200",
        },
      },
      ".pagination > li:not(:last-child)": {
        borderRightWidth: "1px",
        borderStyle: "solid",
        borderColor: "gray.400",
      },
      ".pagination > li.selected": {
        fontWeight: "semibold",
        bg: "gray.400",
        pointerEvents: "none",
      },
      ".pagination > li.disabled": {
        opacity: 0.5,
        pointerEvents: "none",
      },
      ".datepicker-calendar > .chakra-stack": {
        border: "none",
      },
      ".datepicker-calendar .chakra-divider": {
        border: "none",
      },
    },
  },
  colors,
  fonts: {
    heading: "eurostile, sans-serif",
    body: "eurostile, sans-serif",
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "primary",
      },
      baseStyle: {
        rounded: "full",
        fontWeight: "normal",
      },
      variants: {
        solid: {
          color: "secondary.500",
        },
        outline: {
          _active: {
            backgroundColor: "secondary.500",
            color: "white",
          },
          _hover: {
            backgroundColor: "secondary.500",
            color: "white",
          },
        },
        ghost: {
          _hover: {
            textDecoration: "underline",
          },
        },
      },
    },
    Input: {
      defaultProps: {
        variant: "filled",
      },
      baseStyle: {
        field: {
          // minWidth: "230px",
        },
      },
      variants: {
        filled: {
          field: {
            rounded: "md",
            border: "1px solid",
            backgroundColor: "gray.100",
            _hover: {
              backgroundColor: "gray.400",
            },
            _focus: {
              backgroundColor: "gray.100",
              boxShadow: "none",
              borderColor: "secondary.500",
            },
            _invalid: {
              borderColor: "error",
            },
          },
        },
      },
    },
    Textarea: {
      defaultProps: {
        variant: "filled",
      },
      variants: {
        filled: {
          field: {
            rounded: "md",
            border: "1px solid",
            backgroundColor: "gray.100",
            _hover: {
              backgroundColor: "gray.400",
            },
            _focus: {
              backgroundColor: "gray.100",
              boxShadow: "none",
            },
          },
        },
      },
    },
    Select: {
      defaultProps: {
        variant: "filled",
      },
      variants: {
        filled: {
          field: {
            rounded: "md",
            backgroundColor: "gray.100",
            border: "1px solid",
            _hover: {
              backgroundColor: "gray.400",
            },
            _focus: {
              backgroundColor: "gray.100",
              boxShadow: "none",
              borderColor: "secondary.500",
            },
            _invalid: {
              borderColor: "error",
            },
          },
        },
        outline: {
          field: {
            borderColor: "gray.400",
            _hover: {
              backgroundColor: "gray.200",
            },
            _focus: {
              boxShadow: "none",
              borderColor: "secondary.500",
            },
          },
        },
      },
      sizes: {
        sm: {
          field: {
            pb: "0",
            boxSizing: "content-box",
            rounded: "md",
            w: "auto",
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: "semibold",
        mb: "1",
        color: "#0d2245",
      },
    },
    FormError: {
      baseStyle: {
        text: {
          mt: "1",
          color: "error",
        },
      },
    },
    Modal: {
      defaultProps: {
        size: "lg",
      },
      baseStyle: {
        dialogContainer: {
          px: { base: "4", lg: "50px" },
        },
        dialog: {
          bg: "gray.50",
        },
        header: {
          py: "8",
          px: {
            base: "4",
            lg: "6",
          },
          textAlign: "center",
          fontSize: "2xl",
        },
        body: {
          px: {
            base: "6",
            lg: "8",
          },
          pt: "0",
          pb: "8",
        },
      },
    },
    Alert: {
      baseStyle: {
        container: {
          rounded: "md",
        },
        title: {
          color: "var(--alert-fg)",
        },
      },
    },
    Table: {
      baseStyle: {
        th: {
          fontWeight: "normal",
          textTransform: "capitalize",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          color: "transparent",
          border: "1px solid",
          borderColor: "secondary.500",
          _checked: {
            color: "secondary.500",
            borderColor: "secondary.500",
            backgroundColor: "transparent",
          },
          _hover: {
            borderColor: "secondary.500 !important",
            backgroundColor: "transparent !important",
          },
        },
      },
      sizes: {
        lg: {
          control: {
            width: "6",
            height: "6",
            borderRadius: "5px",
          },
        },
      },
    },
    Popover: {
      baseStyle: {
        content: {
          bg: "gray.100",
          shadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16) !important",
          border: "none",
          rounded: "5px",
          width: "auto",
        },
      },
    },
  },
});
