import { FC } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const SearchIcon: FC<Omit<IconBaseProps, "size">> = (props) => (
  <IconBase size="15.441" viewBox="0 0 15.441 15.441" {...props}>
    <g>
      <path
        d="M17.152 10.826A6.326 6.326 0 1 1 10.826 4.5a6.326 6.326 0 0 1 6.326 6.326z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-4 -4)"
      />
      <path
        d="m28.415 28.415-3.44-3.44"
        transform="translate(-13.681 -13.681)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </IconBase>
);

export default SearchIcon;
