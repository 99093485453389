import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";

import PasswordInputGroup from "../common/PasswordInputGroup";
import { useAppDispatch } from "../../redux/store";
import { login } from "../../redux/auth/authSlice";
import { isAxiosError } from "axios";

interface ILoginFormValues {
  username: string;
  password: string;
}

const LoginForm: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ILoginFormValues>({
    defaultValues: {
      username: process.env.REACT_APP_DEFAULT_LOGIN || "",
      password: process.env.REACT_APP_DEFAULT_PASS || "",
    },
  });

  const onSubmit: SubmitHandler<ILoginFormValues> = async (values) => {
    try {
      await dispatch(login(values)).unwrap();
    } catch (err) {
      setError("username", {
        message:
          isAxiosError(err) && err.response?.status === 401
            ? "Nieprawidłowy login lub hasło"
            : "Coś poszło nie tak. Spróbuj ponownie.",
      });
    }
  };

  return (
    <Box as="form" w="full" maxW="xs" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4">
        <FormControl isInvalid={!!errors.username}>
          <FormLabel>{t("common.forms.login.label")}</FormLabel>
          <Input
            size="lg"
            placeholder={t("common.forms.login.placeholder") as string}
            {...register("username", {
              required: true,
              // TODO:
              // validate: (value) => /^.{8,}$/.test(value),
            })}
          />
          {errors.username && (
            <FormErrorMessage>
              {errors.username.message ||
                t(`common.forms.login.${errors.username.type || "validate"}`)}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.password}>
          <FormLabel>{t("common.forms.password.label")}</FormLabel>
          <PasswordInputGroup>
            <Input
              size="lg"
              placeholder={t("common.forms.password.placeholder") as string}
              autoComplete="current-password"
              {...register("password", { required: true })}
            />
          </PasswordInputGroup>
          {errors.password && (
            <FormErrorMessage>
              {t(`common.forms.password.${errors.password.type || "invalid"}`)}
            </FormErrorMessage>
          )}
        </FormControl>
      </VStack>

      <Button
        type="submit"
        size="lg"
        mt="6"
        w="full"
        textTransform="uppercase"
        color="white"
        backgroundColor="primary.400"
        _hover={{
          backgroundColor: "primary.500",
        }}
        isLoading={isSubmitting}
      >
        {t("login.submit")}
      </Button>
    </Box>
  );
};

export default LoginForm;
