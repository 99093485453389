import { FC, memo, useState } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";

import DocPreviewMenu from "./DocPreviewMenu";
import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import PDFViewerModal from "../common/PDFViewerModal";
import type { IDocument } from "../../types/document";

interface IMainDocPreviewProps extends BoxProps {
  doc: IDocument;
}

const MainDocPreview: FC<IMainDocPreviewProps> = ({ doc, ...rest }) => {
  const getDocFileUrl = useGetDocFileUrl();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Box role="button" onClick={() => setModalOpen(true)} {...rest}>
        <DocPreviewMenu
          doc={doc}
          actions={["print", "download"]}
          position="absolute"
          top="2.5"
          left="50%"
          transform="auto"
          translateX="-50%"
          zIndex="2"
        />
        <Viewer
          fileUrl={getDocFileUrl(doc.id)}
          defaultScale={SpecialZoomLevel.PageFit}
        />
      </Box>

      <PDFViewerModal
        doc={doc}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default memo(MainDocPreview);
