import type { IClient } from "./client";
import type { IConstruction } from "./construction";

export enum UserRole {
  SuperAdmin = "ROLE_SUPERADMIN",
  Admin = "ROLE_ADMIN",
  Manager = "ROLE_MANAGER",
  Head = "ROLE_HEAD",
  Foreman = "ROLE_FOREMAN",
  Employee = "ROLE_EMPLOYEE",
}

export interface IUserAccess {
  type: "invoice" | "protocol" | "possession";
  granted: boolean;
}

export interface IUser {
  id: string;
  userId: number;
  name: string;
  username: string;
  email: string;
  roles: UserRole[];
  active: boolean;
  clients: Pick<IClient, "id" | "name">[];
  constructions: Pick<IConstruction, "id" | "clientId" | "name">[];
  access: IUserAccess[];
}
