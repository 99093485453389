import { SVGProps } from "react";

const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="nonzero"
        d="m27.192 8.1.707.707L18.706 18l9.193 9.193-.707.707-9.193-9.193-9.192 9.193-.707-.707 9.192-9.193L8.1 8.807l.707-.707L18 17.292 27.192 8.1Z"
      />
    </svg>
  );
};

export default CloseIcon;
