import { SVGProps } from "react";

const MessageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    {...props}
  >
    <g>
      <path
        d="M26.866 10.083 25.242 11.7a5.721 5.721 0 0 1 0 8.1l1.624 1.624a8.021 8.021 0 0 0 0-11.341z"
        transform="translate(-3.205 -5.874)"
        fill="currentColor"
      />
      <path
        d="M21.641 12.723v-7.1h-2.155l-6.463 5.457H4.4l-2.15 1.09v7.636L4.4 20.9l5.386 8.727h3.232L11 20.9h2.02l6.464 4.364h2.155v-7.1a2.575 2.575 0 0 0 2.155-2.72 2.575 2.575 0 0 0-2.153-2.721z"
        transform="translate(-2.25 -5.625)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default MessageIcon;
