import { FC } from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const LoadingTable: FC = () => (
  <Flex
    w="full"
    justify={"center"}
    borderRadius={{ base: "none", lg: "xl" }}
    p={8}
    border="1px solid"
    borderColor="gray.400"
  >
    <Spinner color="secondary" size="xl" />
  </Flex>
);

export default LoadingTable;
