import { Box, Flex, HStack, Img, Text } from "@chakra-ui/react";

import MobileMenu from "./MobileMenu";
import useLogout from "../../hooks/useLogout";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import LetterAvatar from "../common/LetterAvatar";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";
import SessionTimer from "../common/SessionTimer";

const Navbar = () => {
  const logout = useLogout();
  const tokenPayload = useAccessTokenPayload();

  return (
    <Box
      position="sticky"
      top={0}
      w="100%"
      boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.33)"
      zIndex={10}
    >
      <Flex
        display="flex"
        justify="space-between"
        align="center"
        h="20"
        px={{ base: "4", lg: "6" }}
        bg="gray.50"
      >
        <Box>
          <Img
            src="assets/logo.png"
            srcSet="assets/logo@2x.png 2x,
             assets/logo@3x.png 3x"
          />
          <Text mt="1" textTransform="uppercase" textAlign="center">
            Strefa Partnera
          </Text>
        </Box>

        <HStack spacing={{ base: "4", lg: "10" }}>
          <HStack spacing="4">
            <LetterAvatar letters={tokenPayload?.name} />
            {tokenPayload?.name && (
              <Text display={{ base: "none", lg: "block" }}>
                {tokenPayload.name}
              </Text>
            )}
          </HStack>

          <SessionTimer />
          <Box
            as="button"
            onClick={() => logout()}
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            color="secondary.500"
            _hover={{
              textDecoration: "underline",
            }}
          >
            <LogoutIcon />
            <Text display={{ base: "none", lg: "inline" }} fontSize="sm" mt={1}>
              Wyloguj
            </Text>
          </Box>
        </HStack>
      </Flex>

      <MobileMenu />
    </Box>
  );
};

export default Navbar;
