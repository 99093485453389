import { FC, ReactElement, cloneElement, useState } from "react";
import {
  Icon,
  IconButton,
  InputGroup,
  InputGroupProps,
  InputRightElement,
} from "@chakra-ui/react";
import EyeIcon from "../../assets/icons/EyeIcon";

interface PasswordInputGroupProps extends InputGroupProps {}

const PasswordInputGroup: FC<PasswordInputGroupProps> = ({
  children,
  ...rest
}) => {
  const [isHidden, setHidden] = useState(true);

  return (
    <InputGroup {...rest}>
      {cloneElement(children as ReactElement, {
        type: isHidden ? "password" : "text",
      })}

      <InputRightElement h="full">
        <IconButton
          aria-label={isHidden ? "show" : "hide"}
          onClick={() => setHidden(!isHidden)}
          variant="unstyled"
          display="flex"
          alignItems="center"
          icon={<Icon as={EyeIcon} w="auto" h="auto" isOpen={isHidden} />}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInputGroup;
