import { SVGProps } from "react";

const FolderIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="m92.884 41.935-16.191 42.75H7.499l15.759-42.75h69.626ZM30.428 15l10.933 10.727H76.73v10.516H17.808L6.999 82.649V15h23.429Z"
        />
        <path
          fill="#002765"
          fillRule="nonzero"
          d="M6.5 14.5h24.842L42.07 25.227h35.155v11.727l-59.099-.001-9.092 40.926 13.71-36.563h70.792L77.071 85.224 7.4 85.223H6.5V14.5Zm85.592 27.816H23.436L7.721 84.223h68.656l15.715-41.907ZM30.928 15.5H7.499v64.672l9.824-44.218h58.901v-9.727h-34.57L30.929 15.5Z"
        />
      </g>
    </svg>
  );
};

export default FolderIllustration;
