import { FC } from "react";
import { Text, Link, Icon } from "@chakra-ui/react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";

interface ISidebarLinkProps {
  name: string;
  pathname: string;
  icon: FC;
  statusIcon?: FC;
}

const SidebarLink: FC<ISidebarLinkProps> = ({
  name,
  pathname,
  icon: LinkIcon,
  statusIcon: StatusIcon,
}) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(pathname);

  return (
    <Link
      as={RouterLink}
      to={pathname}
      backgroundColor={isActive ? "secondary.500" : "transparent"}
      rounded="full"
      pl="4"
      pr="5"
      py="3"
      marginBottom="2"
      display="flex"
      alignItems="center"
      _hover={
        !isActive
          ? {
              backgroundColor: "gray.400",
              color: "secondary.500",
            }
          : {}
      }
      color={isActive ? "white" : "secondary.500"}
    >
      <Icon as={LinkIcon} mr="3" w="auto" h="auto" />
      <Text w="full">{name}</Text>
      {StatusIcon && (
        <Icon as={StatusIcon} ml="3" w="21px" h="auto" flexShrink={0} />
      )}
    </Link>
  );
};

export default SidebarLink;
