import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, type SubmitHandler } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";

import {
  useCreateClientMutation,
  useUpdateClientMutation,
} from "../../redux/api/clientsEndpoints";
import { IClient } from "../../types/client";
import useErrorMsg from "../../hooks/useErrorMsg";

interface IEditClientFormValues {
  id: IClient["id"];
  name: string;
}

interface IEditClientFormProps {
  client?: IClient;
  onClose: () => void;
}

const EditClientForm: FC<IEditClientFormProps> = ({ client, onClose }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditClientFormValues>({
    defaultValues: client ?? {
      id: "",
      name: "",
    },
  });

  const [createClient, createState] = useCreateClientMutation();
  const [updateClient, updateState] = useUpdateClientMutation();

  const isLoading = createState.isLoading || updateState.isLoading;
  const isSuccess = createState.isSuccess || updateState.isSuccess;
  const error = createState.error || updateState.error;
  const errorMsg = useErrorMsg(error);

  const onSubmit: SubmitHandler<IEditClientFormValues> = (values) => {
    if (client) {
      updateClient({ id: client.id, client: { ...client, ...values } });
    } else {
      createClient(values);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Box as="form" w="full" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4">
        {errorMsg && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{errorMsg}</AlertTitle>
          </Alert>
        )}

        <FormControl isInvalid={!!errors.name}>
          <FormLabel>{t("common.forms.clientName.label")}</FormLabel>
          <Input
            size="lg"
            placeholder={t("common.forms.clientName.placeholder") as string}
            {...register("name", {
              required: true,
            })}
          />

          {errors.name && (
            <FormErrorMessage>
              {t(`common.forms.clientName.${errors.name.type || "validate"}`)}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.id}>
          <FormLabel>{t("common.forms.clientId.label")}</FormLabel>
          <Input
            size="lg"
            type="number"
            step={0}
            maxLength={5}
            onKeyDown={(e) => {
              if (
                !/\d/.test(e.key) &&
                !/^Arrow/.test(e.key) &&
                e.key !== "Backspace" &&
                !e.metaKey
              ) {
                e.preventDefault();
              }
            }}
            placeholder={t("common.forms.clientId.placeholder") as string}
            {...register("id", {
              required: true,
              minLength: 5,
              maxLength: 5,
            })}
          />

          {errors.id && (
            <FormErrorMessage>
              {t(`common.forms.clientId.${errors.id.type || "validate"}`)}
            </FormErrorMessage>
          )}
        </FormControl>
      </VStack>

      <HStack spacing="2" mt="6">
        <Button
          type="submit"
          colorScheme="secondary"
          variant="outline"
          isLoading={isLoading}
        >
          Zapisz
        </Button>
        <Button
          type="button"
          colorScheme="secondary"
          variant="ghost"
          fontWeight="medium"
          onClick={() => onClose()}
        >
          Anuluj
        </Button>
      </HStack>
    </Box>
  );
};

export default EditClientForm;
