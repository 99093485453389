import { SVGProps } from "react";

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="currentColor"
      {...props}
    >
      <g fillRule="nonzero">
        <path d="M2 15.333h10.667V2H2zM2 26h10.667v-8H2zM15.333 26H26V12.667H15.333zM15.333 2v8H26V2z" />
      </g>
    </svg>
  );
};

export default DashboardIcon;
