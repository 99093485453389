import { FC } from "react";
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
} from "@chakra-ui/react";

export interface IAlertDialogProps extends AlertDialogProps {
  title?: string;
  body?: string;
}

const AlertDialog: FC<IAlertDialogProps> = ({
  title,
  body,
  children,
  ...rest
}) => {
  return (
    <ChakraAlertDialog {...rest}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogBody>{children}</AlertDialogBody>
      </AlertDialogContent>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;
