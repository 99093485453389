import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { TFilters } from "../types/filters";

const useFiltersParams = <F extends TFilters>(filters: F) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const values = useMemo(
    () =>
      Object.keys(filters).reduce(
        (acc, key) => ({
          ...acc,
          [key]: searchParams.get(key) || "",
        }),
        {}
      ) as Record<keyof F, any>,
    [filters, searchParams]
  );

  const onChange = (newFilterValues: Record<keyof F, any>) => {
    const setValues = (values: Record<string, any>) => {
      for (const [key, value] of Object.entries(values)) {
        if (value) {
          if (
            typeof value === "object" &&
            !Array.isArray(value) &&
            !(value instanceof Date)
          ) {
            setValues(value);
          } else {
            searchParams.set(key, value.toString());
          }
        } else {
          searchParams.delete(key);
        }
      }
    };

    setValues(newFilterValues);
    setSearchParams(searchParams);
  };

  return { values, onChange };
};

export default useFiltersParams;
