import { FC } from "react";

interface IChevronIconProps {
  isActive?: boolean;
}

const ChevronIcon: FC<IChevronIconProps> = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    width="16"
    viewBox="0 0 407.437 407.437"
    xmlSpace="preserve"
    style={isActive ? { transform: "rotate(180deg)" } : {}}
  >
    <polygon
      fill="currentColor"
      points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 "
    />
  </svg>
);

export default ChevronIcon;
