import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";

import type { TRootState } from "../store";

interface IGenericAuthRes {
  token: string;
  refresh_token: string;
}

interface AuthState {
  refreshToken?: string;
  accessToken?: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const initialState: AuthState = {
  refreshToken: undefined,
  accessToken: undefined,
};

export const login = createAsyncThunk(
  "auth/login",
  async (
    { username, password }: { username: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.post(`${API_URL}/login`, {
        username,
        password,
      });

      return res.data as IGenericAuthRes;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const refreshTokens = createAsyncThunk(
  "auth/refresh",
  async (refreshToken: string) => {
    const res = await axios.post(`${API_URL}/token/refresh`, {
      refresh_token: refreshToken,
    });

    return res.data as IGenericAuthRes;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRefreshToken: (
      state,
      action: PayloadAction<AuthState["refreshToken"]>
    ) => {
      state.refreshToken = action.payload;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.refreshToken = action.payload.refresh_token;
        state.accessToken = action.payload.token;
      })
      .addCase(refreshTokens.fulfilled, (state, action) => {
        state.accessToken = action.payload.token;
      })
      .addCase(refreshTokens.rejected, (state) => {
        Object.assign(state, initialState);
      }),
});

export const { setRefreshToken, logout } = authSlice.actions;

export const selectRefreshToken = (state: TRootState) =>
  state.auth.refreshToken;
export const selectAccessToken = (state: TRootState) => state.auth.accessToken;

export default authSlice.reducer;
