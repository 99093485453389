import { FC, useEffect, useMemo, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  AspectRatio,
  Box,
  Collapse,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
  useGetDocumentFilesQuery,
  useGetDocumentsQuery,
} from "../../redux/api/documentsEndpoints";
import MainDocLabel from "../docs/MainDocLabel";
import useDesktopQuery from "../../hooks/useDesktopQuery";
import DocFilesUploader from "../docs/DocFilesUploader";
import MainDocPreview from "../docs/MainDocPreview";
import DocFilePreviewItem from "../docs/DocFilePreviewItem";
import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import useDownloadUrl from "../../hooks/useDownloadUrl";
import { useGetClientQuery } from "../../redux/api/clientsEndpoints";
import { useGetConstructionQuery } from "../../redux/api/constructionsEndpoints";
import GoBackIcon from "../../assets/icons/GoBackIcon";
import useCanModifyDocs from "../../hooks/useCanModifyDocs";
import FileViewerModal from "../docs/FileViewerModal";
import { IDocumentFile } from "../../types/document";

const DocDetails: FC = () => {
  const navigate = useNavigate();
  const { clientId, constructionId, documentId } = useParams();
  const isDesktop = useDesktopQuery();
  const [showUpload, setShowUpload] = useState(false);
  const getDocFileUrl = useGetDocFileUrl();
  const downloadUrl = useDownloadUrl();
  const canModifyDocs = useCanModifyDocs();
  const location = useLocation();

  const { data: client } = useGetClientQuery(clientId || skipToken);
  const { data: construction } = useGetConstructionQuery(
    constructionId || skipToken
  );

  const getDocsParams = useMemo(
    () => ({
      "filters[id]": documentId,
      page: 1,
      perPage: 1,
    }),
    [documentId]
  );
  const { data: getDocsData } = useGetDocumentsQuery(getDocsParams);
  const doc = useMemo(() => getDocsData?.data[0], [getDocsData]);

  const getFilesParams = useMemo(
    () =>
      doc?.id ? { id: doc.id, params: { page: 1, perPage: 500 } } : skipToken,
    [doc?.id]
  );
  const { data: getFilesData } = useGetDocumentFilesQuery(getFilesParams);
  const files = getFilesData?.data;

  const [activeFileId, setActiveFileId] = useState<IDocumentFile["id"] | null>(
    null
  );
  const activeFile = useMemo(
    () =>
      activeFileId
        ? files?.find((file) => file.id === activeFileId)
        : undefined,
    [activeFileId, files]
  );

  useEffect(() => {
    if (getDocsData && !doc) {
      navigate("/docs", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!getDocsData, !!doc]);

  const handleAllFilesDownload = async () => {
    for (const file of getFilesData!.data) {
      try {
        await downloadUrl(getDocFileUrl(doc!.id, file.id), file.filename);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      {doc ? (
        <>
          {client && construction ? (
            <HStack
              spacing="1"
              mb="2.5"
              mx={{ base: "2.5", lg: "4" }}
              fontWeight="medium"
            >
              <HStack
                as={RouterLink}
                to={
                  location.state?.prevLocation ||
                  `/clients/${client.id}/constructions/${construction.id}`
                }
                spacing="1"
              >
                <GoBackIcon />
                <Text as="span">
                  {client.name} / {construction.name}
                </Text>
              </HStack>

              <Text as="span">/</Text>
              <Text as="span">{doc?.file.documentNumber || "-"}</Text>
            </HStack>
          ) : !clientId ? (
            <HStack
              spacing="1"
              mb="2.5"
              mx={{ base: "2.5", lg: "4" }}
              fontWeight="medium"
            >
              <HStack
                as={RouterLink}
                to={location.state?.prevLocation || "/docs"}
                spacing="1"
              >
                <GoBackIcon />
                <Text as="span">Dokumenty</Text>
              </HStack>

              <Text as="span">/</Text>
              <Text as="span">{doc?.file.documentNumber || "-"}</Text>
            </HStack>
          ) : null}

          <Box
            roundedTop="5px"
            bg="gray.400"
            p={{ base: "0", lg: "5" }}
            pb={{ base: "5", lg: "5" }}
            mb={{ base: "-1.875rem", lg: "0" }}
            mx={{ base: "0", lg: "4" }}
            flexGrow="1"
            display={{ base: "block", lg: "flex" }}
            flexDir="column"
            maxHeight={{ base: "none", lg: "calc(100vh - 176px)" }}
          >
            {isDesktop ? (
              <>
                <HStack
                  flexGrow="1"
                  overflow="hidden"
                  spacing="6"
                  alignItems="stretch"
                >
                  <VStack
                    spacing="3"
                    flex="1"
                    alignItems="stretch"
                    overflow="hidden"
                  >
                    <Box>
                      <Text mb="2">Dokument podstawowy</Text>
                      <MainDocLabel doc={doc} />
                    </Box>

                    <Flex mb="-1" align="center" justify="space-between">
                      <Text>Dokumenty dodatkowe</Text>

                      {files && files.length > 1 && (
                        <Text
                          role="button"
                          onClick={() => handleAllFilesDownload()}
                          textDecoration="underline"
                        >
                          Pobierz wszystkie ({getFilesData.data.length})
                        </Text>
                      )}
                    </Flex>

                    {files ? (
                      <Wrap flex="1" spacing="5" overflowY="auto">
                        {getFilesData.data.map((file) => (
                          <WrapItem key={file.id} w="152px">
                            <DocFilePreviewItem
                              w="full"
                              docId={doc.id}
                              file={file}
                              showPreview={setActiveFileId}
                            />
                          </WrapItem>
                        ))}
                      </Wrap>
                    ) : (
                      <Box flex="1">
                        <Spinner display="block" mx="auto" my="4" />
                      </Box>
                    )}

                    {canModifyDocs && (
                      <Text
                        role="button"
                        onClick={() => setShowUpload(!showUpload)}
                        textDecoration="underline"
                      >
                        Prześlij pliki dodatkowe
                      </Text>
                    )}
                  </VStack>

                  <Box flexShrink="0" flexGrow="1">
                    <AspectRatio
                      bg="#efefef"
                      maxH="full"
                      border="solid 1px #c9c9c9"
                      ratio={380 / 522}
                    >
                      <MainDocPreview doc={doc} position="relative" />
                    </AspectRatio>
                  </Box>
                </HStack>

                {canModifyDocs && (
                  <Box flexShrink="0">
                    <Collapse in={showUpload} animateOpacity>
                      <DocFilesUploader docId={doc.id} mt="5" />
                    </Collapse>
                  </Box>
                )}
              </>
            ) : (
              <Box px="2.5">
                <Text py="2">Dokument podstawowy</Text>
                <MainDocLabel doc={doc} mx="-2.5" />

                <AspectRatio
                  mt="28px"
                  maxH="70vh"
                  bg="#efefef"
                  border="solid 1px #c9c9c9"
                  ratio={380 / 522}
                >
                  <MainDocPreview doc={doc} position="relative" />
                </AspectRatio>

                <Flex mt="22px" mb="2.5" align="center" justify="space-between">
                  <Text>Dokumenty dodatkowe</Text>

                  {files && files.length > 1 && (
                    <Text
                      role="button"
                      onClick={() => handleAllFilesDownload()}
                      textDecoration="underline"
                    >
                      Pobierz wszystkie ({getFilesData.data.length})
                    </Text>
                  )}
                </Flex>

                {files ? (
                  <SimpleGrid columns={2} gap="2.5">
                    {getFilesData.data.map((file) => (
                      <DocFilePreviewItem
                        key={file.id}
                        w="full"
                        docId={doc.id}
                        file={file}
                        showPreview={setActiveFileId}
                      />
                    ))}
                  </SimpleGrid>
                ) : (
                  <Box flex="1">
                    <Spinner display="block" mx="auto" mt="4" />
                  </Box>
                )}

                {canModifyDocs && (
                  <>
                    <Text
                      role="button"
                      onClick={() => setShowUpload(!showUpload)}
                      mt="54px"
                      textDecoration="underline"
                    >
                      Prześlij pliki dodatkowe
                    </Text>

                    <Collapse in={showUpload} animateOpacity>
                      <DocFilesUploader docId={doc.id} mt="3" />
                    </Collapse>
                  </>
                )}
              </Box>
            )}
          </Box>

          {files && activeFile && (
            <FileViewerModal
              isOpen
              onClose={() => setActiveFileId(null)}
              docId={doc.id}
              files={files}
              activeFile={activeFile}
              changeActiveFile={setActiveFileId}
            />
          )}
        </>
      ) : (
        <Spinner size="lg" display="block" mx="auto" my="8" />
      )}
    </>
  );
};

export default DocDetails;
