import { api } from "./index";
import { TAG_LIST_SYMBOL } from "../../constants/redux";
import type {
  IPaginatedReponse,
  IPaginatedRequestQueryParams,
} from "../../types/api";
import type { IConstruction } from "../../types/construction";

type TGetConstructionsQueryParams = IPaginatedRequestQueryParams & {
  ["filters[id]"]?: string;
  ["filters[name]"]?: string;
  ["filters[starts_with]"]?: string;
  ["filters[client_id]"]?: string;
};

export const constructionsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getConstructions: builder.query<
      IPaginatedReponse<IConstruction>,
      TGetConstructionsQueryParams
    >({
      query: (params) =>
        `/constructions?${new URLSearchParams(
          params as unknown as Record<string, string>
        ).toString()}`,
      providesTags: (result) =>
        result
          ? [
              { id: TAG_LIST_SYMBOL, type: "Construction" },
              ...result.data.map((client) => ({
                id: client.id,
                type: "Construction" as const,
              })),
            ]
          : [],
    }),
    createConstruction: builder.mutation<void, IConstruction>({
      query: (body) => ({
        url: "/constructions",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: "Construction", id: TAG_LIST_SYMBOL }],
    }),
    getConstruction: builder.query<IConstruction, IConstruction["id"]>({
      query: (id) => `/constructions/${id}`,
      providesTags: (result) =>
        result ? [{ id: result.id, type: "Construction" }] : [],
    }),
    updateConstruction: builder.mutation<void, IConstruction>({
      query: (body) => ({
        url: `/constructions/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              { type: "Construction", id: TAG_LIST_SYMBOL },
              { type: "Construction", id },
            ],
    }),
    deleteConstruction: builder.mutation<void, IConstruction["id"]>({
      query: (id) => ({
        url: `/constructions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        error
          ? []
          : [
              { type: "Construction", id: TAG_LIST_SYMBOL },
              { type: "Construction", id },
            ],
    }),
  }),
});

export const {
  useGetConstructionsQuery,
  useCreateConstructionMutation,
  useGetConstructionQuery,
  useUpdateConstructionMutation,
  useDeleteConstructionMutation,
} = constructionsEndpoints;
