import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, HStack } from "@chakra-ui/react";

import Dialog from "./Dialog";
import EditConstructionForm from "./EditConstructionForm";
import { useDeleteConstructionMutation } from "../../redux/api/constructionsEndpoints";
import type { IConstruction } from "../../types/construction";
import ConfirmDialog from "./ConfirmDialog";

interface IConstructionRowActions {
  construction: IConstruction;
  canEdit?: boolean;
}

const ConstructionRowActions = ({
  construction,
  canEdit,
}: IConstructionRowActions) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [deleteConstruction, { isLoading, isSuccess, originalArgs }] =
    useDeleteConstructionMutation();

  const isDeleting = originalArgs === construction.id && isLoading;
  const isDeleted = originalArgs === construction.id && isSuccess;

  useEffect(() => {
    if (isDeleting || isDeleted) {
      setDeleteDialogOpen(false);
    }
  }, [isDeleting, isDeleted]);

  return (
    <>
      <HStack spacing={4} justifyContent="flex-end">
        {canEdit && (
          <>
            <Button
              ref={cancelRef}
              variant="ghost"
              colorScheme="secondary"
              onClick={() => setDeleteDialogOpen(true)}
              size="sm"
              isLoading={isDeleting}
              isDisabled={isDeleted}
            >
              Usuń
            </Button>
            <Button
              variant="outline"
              colorScheme="secondary"
              onClick={() => setEditDialogOpen(true)}
              size="sm"
              isDisabled={isDeleting || isDeleted}
            >
              Edytuj
            </Button>
          </>
        )}
        <Button
          variant="outline"
          colorScheme="secondary"
          onClick={() =>
            navigate(`constructions/${construction.id}`, {
              state: { prevLocation: location },
            })
          }
          size="sm"
          isDisabled={isDeleting || isDeleted}
        >
          Przejdź
        </Button>
      </HStack>

      {canEdit && (
        <>
          <Dialog
            isOpen={isEditDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            title="Edytuj obiekt"
          >
            <EditConstructionForm
              clientId={construction.clientId}
              construction={construction}
              onClose={() => setEditDialogOpen(false)}
            />
          </Dialog>

          <ConfirmDialog
            leastDestructiveRef={cancelRef}
            isOpen={isDeleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            title="Usuwanie obiektu"
            confirmLabel="Usuń obiekt"
            onConfirm={() => deleteConstruction(construction.id)}
            onCancel={() => setDeleteDialogOpen(false)}
          >
            Czy jesteś pewny, że chcesz usunąć obiekt <b>{construction.name}</b>
            ?
          </ConfirmDialog>
        </>
      )}
    </>
  );
};

export default ConstructionRowActions;
