import { FC, ReactNode } from "react";
import AlertDialog, { type IAlertDialogProps } from "./AlertDialog";
import { Button, HStack, Text } from "@chakra-ui/react";

interface IConfirmDialogProps extends IAlertDialogProps {
  confirmLabel: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: FC<IConfirmDialogProps> = ({
  confirmLabel,
  onConfirm,
  onCancel,
  children,
  ...rest
}) => (
  <AlertDialog {...rest}>
    <Text textAlign="center">{children}</Text>
    <HStack spacing="2" mt="8">
      <Button
        colorScheme="secondary"
        variant="outline"
        onClick={() => onConfirm()}
      >
        {confirmLabel}
      </Button>
      <Button
        type="button"
        colorScheme="secondary"
        variant="ghost"
        fontWeight="medium"
        onClick={() => onCancel()}
      >
        Anuluj
      </Button>
    </HStack>
  </AlertDialog>
);

export default ConfirmDialog;
