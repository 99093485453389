import { SVGProps } from "react";

const FilesIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" {...props}>
      <g fill="none" fillRule="nonzero">
        <path
          fill="#EBEBEB"
          d="M79.193 67.572H44.35a5.313 5.313 0 0 1-5.307-5.307V15.807A5.313 5.313 0 0 1 44.35 10.5h23.022L84.5 27.629v34.636a5.313 5.313 0 0 1-5.307 5.307Z"
        />
        <path
          fill="#002765"
          d="M44.35 11a4.813 4.813 0 0 0-4.807 4.807v46.458a4.813 4.813 0 0 0 4.807 4.807h34.843A4.813 4.813 0 0 0 84 62.265v-34.43L67.165 11H44.35m0-1H67.58L85 27.422v34.843c0 3.207-2.6 5.807-5.807 5.807H44.35a5.807 5.807 0 0 1-5.807-5.807V15.807c0-3.207 2.6-5.807 5.807-5.807Z"
        />
        <path
          fill="#EBEBEB"
          stroke="#002765"
          strokeLinejoin="round"
          d="M67.579 10v17.422h17.422M73.386 41.939H50.157M73.386 53.554H50.157M55.964 30.325h-5.807"
        />
        <path
          fill="#D1D1D1"
          d="M67.421 77.774H32.578a5.313 5.313 0 0 1-5.307-5.307V26.009a5.313 5.313 0 0 1 5.307-5.307H55.6l17.128 17.129v34.636a5.313 5.313 0 0 1-5.307 5.307Z"
        />
        <path
          fill="#002765"
          d="M32.578 21.202a4.813 4.813 0 0 0-4.807 4.807v46.458a4.813 4.813 0 0 0 4.807 4.807h34.843a4.813 4.813 0 0 0 4.807-4.807v-34.43L55.393 21.203H32.578m0-1h23.229l17.421 17.422v34.843c0 3.207-2.6 5.807-5.807 5.807H32.578a5.807 5.807 0 0 1-5.807-5.807V26.009c0-3.207 2.6-5.807 5.807-5.807Z"
        />
        <path
          fill="#D1D1D1"
          stroke="#002765"
          strokeLinejoin="round"
          d="M55.807 20.202v17.422h17.422M61.614 52.141H38.385M61.614 63.756H38.385M44.192 40.527h-5.807"
        />
        <path
          fill="#FFF"
          d="M55.65 88.76H20.807a5.313 5.313 0 0 1-5.307-5.307V36.995a5.313 5.313 0 0 1 5.307-5.307H43.83l17.128 17.129v34.636a5.313 5.313 0 0 1-5.307 5.307Z"
        />
        <path
          fill="#002765"
          d="M20.807 32.188A4.813 4.813 0 0 0 16 36.995v46.458a4.813 4.813 0 0 0 4.807 4.807H55.65a4.813 4.813 0 0 0 4.807-4.807v-34.43L43.622 32.189H20.807m0-1h23.229L61.457 48.61v34.843c0 3.207-2.6 5.807-5.807 5.807H20.807A5.807 5.807 0 0 1 15 83.453V36.995c0-3.207 2.6-5.807 5.807-5.807Z"
        />
        <path
          fill="#FFF"
          stroke="#002765"
          strokeLinejoin="round"
          d="M44.036 31.188V48.61h17.422M49.843 63.127H26.614M49.843 74.742H26.614M32.421 51.513h-5.807"
        />
      </g>
    </svg>
  );
};

export default FilesIllustration;
