import { FC, useRef, useState } from "react";

import FileMenu, { IFileMenuProps } from "./FileMenu";
import { type IDocumentFile } from "../../types/document";
import useDownloadUrl from "../../hooks/useDownloadUrl";
import { useDeleteDocumentFileMutation } from "../../redux/api/documentsEndpoints";
import ConfirmDialog from "../common/ConfirmDialog";
import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import usePrintUrl from "../../hooks/usePrintUrl";
import useCanModifyDocs from "../../hooks/useCanModifyDocs";

export interface IDocFilePreviewMenuProps extends IFileMenuProps {
  docId: string;
  file: IDocumentFile;
}

const DocFilePreviewMenu: FC<IDocFilePreviewMenuProps> = ({
  docId,
  file,
  onAction,
  ...rest
}) => {
  const canModifyDocs = useCanModifyDocs();
  const downloadUrl = useDownloadUrl();
  const printUrl = usePrintUrl();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // const [isModalOpen, setModalOpen] = useState(false);
  const getDocFileUrl = useGetDocFileUrl();
  const cancelRef = useRef<HTMLDivElement>(null);
  const [deleteFile] = useDeleteDocumentFileMutation();

  return (
    <>
      <FileMenu
        actions={[
          ...(canModifyDocs ? ["delete" as const] : []),
          "download",
          // "preview",
        ]}
        onAction={(action) => {
          if (action === "download") {
            downloadUrl(getDocFileUrl(docId, file.id), file.filename);
          } else if (action === "print") {
            printUrl(getDocFileUrl(docId, file.id), "image");
            // } else if (action === "preview") {
            //   setModalOpen(true);
          } else if (action === "delete") {
            setDeleteDialogOpen(true);
          }

          onAction?.(action);
        }}
        {...rest}
      />

      {canModifyDocs && (
        <ConfirmDialog
          leastDestructiveRef={cancelRef}
          isOpen={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title="Usuwanie dokumentu"
          confirmLabel="Usuń dokument"
          onConfirm={() => deleteFile({ docId, fileId: file.id })}
          onCancel={() => setDeleteDialogOpen(false)}
        >
          Czy jesteś pewny, że chcesz usunąć dokument <b>{file.filename}</b>?
        </ConfirmDialog>
      )}
    </>
  );
};

export default DocFilePreviewMenu;
