import { Button, Flex, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import CloseIcon from "../../assets/icons/CloseIcon";
import MenuIcon from "../../assets/icons/MenuIcon";
import useMenuLinks from "../../hooks/useMenuLinks";

interface IMobileMenuHeaderProps {
  isOpen: boolean;
  onClick: () => void;
}

const MobileMenuHeader = ({ isOpen, onClick }: IMobileMenuHeaderProps) => {
  const { pathname: currentPathname } = useLocation();
  const links = useMenuLinks();
  const currentLink = links.find((link) =>
    currentPathname.startsWith(link.pathame)
  )!;

  const LinkIcon = currentLink?.icon;

  return (
    <Flex
      flexDir={"row"}
      h="68px"
      justifyContent="space-between"
      alignItems={"center"}
      px={4}
    >
      <Flex visibility={isOpen ? "hidden" : "visible"} align="center">
        {LinkIcon && <LinkIcon />}

        <Text fontSize="2xl" ml="3">
          {currentLink?.label}
        </Text>
      </Flex>
      <Button onClick={onClick} variant="unstyled">
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </Button>
    </Flex>
  );
};

export default MobileMenuHeader;
