import { SVGProps } from "react";

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="nonzero"
        d="M11 .5a.5.5 0 0 1 .09.992L11 1.5H4.333a2.833 2.833 0 0 0-2.828 2.66l-.005.173v23.334a2.833 2.833 0 0 0 2.66 2.828l.173.005H11a.5.5 0 0 1 .09.992L11 31.5H4.333a3.833 3.833 0 0 1-3.828-3.636L.5 27.667V4.333C.5 2.283 2.11.608 4.136.505L4.333.5H11Zm11.951 6.756.07.057 8.333 8.334a.449.449 0 0 1 .049.057l-.05-.057A.502.502 0 0 1 31.5 16v.02a.503.503 0 0 1-.005.054L31.5 16a.502.502 0 0 1-.06.238l-.015.025a.222.222 0 0 1-.014.022l-.017.023a.503.503 0 0 1-.024.028l-.016.019-8.333 8.333a.5.5 0 0 1-.765-.638l.057-.07 7.48-7.48H11a.5.5 0 0 1-.09-.992L11 15.5h18.793l-7.48-7.48a.5.5 0 0 1-.057-.637l.057-.07a.5.5 0 0 1 .638-.057Z"
      />
    </svg>
  );
};

export default LogoutIcon;
