import { FC, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Link,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";

import { useGetDocumentsQuery } from "../../redux/api/documentsEndpoints";
import { NOTIFICATIONS_ALERT_KEY } from "../../constants/storage";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";

const Notifications: FC = () => {
  const { username } = useAccessTokenPayload()!;
  const currentDate = format(
    new Date(new Date().setDate(new Date().getDate() - 1)),
    "yyyy-MM-dd"
  );
  const [isClosed, setClosed] = useState(
    () =>
      localStorage.getItem(NOTIFICATIONS_ALERT_KEY) ===
      `${currentDate}:${username}`
  );
  const { data: getDocsData } = useGetDocumentsQuery(
    isClosed
      ? skipToken
      : {
          page: 1,
          perPage: 250,
          "filters[document_date]": currentDate,
        }
  );
  const docs = useMemo(() => getDocsData?.data, [getDocsData]);

  return !isClosed && docs?.length !== 0 ? (
    <Box
      w={{ base: "calc(100% + 2rem)", lg: "full" }}
      rounded={{ base: "none", lg: "md" }}
      ml={{ base: "-4", lg: "0" }}
      p="4"
      bg="primary.200"
    >
      {docs ? (
        <HStack spacing="4" alignItems="start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            viewBox="0 0 23 24"
          >
            <path
              d="M3.751-6.2H1.8V-4h1.951zm.123-9.81H1.68l.14 7.992H3.7z"
              transform="translate(9.053 22.002)"
              fill="currentColor"
            />
            <g stroke="currentColor" fill="none">
              <ellipse cx="11.5" cy="12" rx="11.5" ry="12" stroke="none" />
              <ellipse cx="11.5" cy="12" rx="11" ry="11.5" fill="none" />
            </g>
          </svg>

          <Box flex="1">
            <Accordion allowToggle>
              <AccordionItem border="none">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      p="0"
                      _hover={{ backgroundColor: "inherit" }}
                      borderWidth="0"
                    >
                      <Text
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight="medium"
                        color="secondary.500"
                      >
                        Dodano {docs.length} dokument
                        {docs.length > 1 ? "y" : ""}
                      </Text>

                      <HStack spacing="3">
                        {isExpanded && (
                          <Text
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();

                              localStorage.setItem(
                                NOTIFICATIONS_ALERT_KEY,
                                `${currentDate}:${username}`
                              );
                              setClosed(true);
                            }}
                            textDecoration="underline"
                          >
                            Zamknij
                          </Text>
                        )}

                        <Text textDecoration="underline">
                          {isExpanded ? "Zwiń" : "Rozwiń"}
                        </Text>
                      </HStack>
                    </AccordionButton>

                    <AccordionPanel mt="2" p="0" borderWidth="0">
                      <VStack spacing="1">
                        {docs.map((doc) => (
                          <Text key={doc.id} w="full" color="secondary.500">
                            Dokument{" "}
                            <Link
                              as={RouterLink}
                              to={`/docs/${doc.id}`}
                              textDecoration="underline"
                            >
                              {doc.file.documentNumber || "-"}
                            </Link>{" "}
                            do obiektu{" "}
                            <Link
                              as={RouterLink}
                              to={`/clients/${doc.file.clientId}/constructions/${doc.file.constructionId}`}
                              textDecoration="underline"
                            >
                              {doc.constructionName || doc.file.constructionId}
                            </Link>
                            . Aktualizacja{" "}
                            {format(new Date(doc.attachedAt), "dd.MM.yyyy | ")}g
                            {format(new Date(doc.attachedAt), ". HH:mm")}
                          </Text>
                        ))}
                      </VStack>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </Box>
        </HStack>
      ) : (
        <Spinner mx="auto" display="block" />
      )}
    </Box>
  ) : null;
};

export default Notifications;
