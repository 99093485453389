import { useCallback } from "react";

const useDownloadUrl = () =>
  useCallback(async (url: string, filename: string) => {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error(res.statusText);
    }

    const blob = await res.blob();

    const blobUrl = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(blobUrl);
  }, []);

export default useDownloadUrl;
