import { FC } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const ClientIcon: FC<Omit<IconBaseProps, "size">> = (props) => (
  <IconBase size="28" viewBox="0 0 28 28" fill="currentColor" {...props}>
    <path
      fillRule="nonzero"
      d="M14.313 7.331V2H1v24h26V7.331H14.313Zm-7.987 16H3.663v-2.669h2.662l.001 2.669Zm0-5.331H3.663v-2.669h2.662L6.326 18Zm0-5.331H3.663V10h2.662l.001 2.669Zm0-5.338H3.663V4.662h2.662l.001 2.669Zm5.325 16H8.988v-2.669h2.663v2.669Zm0-5.331H8.988v-2.669h2.663V18Zm0-5.331H8.988V10h2.663v2.669Zm0-5.338H8.988V4.662h2.663v2.669Zm12.687 16H14.313v-2.669h2.662V18h-2.662v-2.669h2.662v-2.668h-2.662V10h10.024l.001 13.331Zm-2.35-10.663h-2.663v2.669h2.663v-2.669Zm0 5.331h-2.663v2.669h2.663v-2.669Z"
    />
  </IconBase>
);

export default ClientIcon;
