import { api } from "./index";
import type { IMessage } from "../../types/message";

export const messageEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getMessage: builder.query<IMessage, void>({
      query: () => "/message",
    }),
    createMessage: builder.mutation<IMessage, Omit<IMessage, "createdAt">>({
      query: (body) => ({
        url: "/message",
        method: "POST",
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            messageEndpoints.util.upsertQueryData("getMessage", undefined, data)
          );
        } catch {}
      },
    }),
    deleteMessage: builder.mutation<void, void>({
      query: (body) => ({
        url: "/message",
        method: "DELETE",
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(messageEndpoints.util.resetApiState());
        } catch {}
      },
    }),
  }),
});

export const {
  useGetMessageQuery,
  useCreateMessageMutation,
  useDeleteMessageMutation,
} = messageEndpoints;
