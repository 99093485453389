import { FC } from "react";
import { Link, Text } from "@chakra-ui/react";

// import {
//   COMPANY_EMAIL,
//   COMPANY_NAME,
//   COMPANY_PHONE,
// } from "../../constants/company";

const FooterLinks: FC = () => (
  <Text fontSize="sm">
    Novarent Sp. z o. o.
    <br />
    E-mail:{" "}
    <Link textDecoration="underline" href="mailto:biuro@novarent.pl">
      biuro@novarent.pl
    </Link>
    <br />
    Dział handlowy: <Link href="tel:+48669250450">+48 669 250 450</Link>
    <br />
    Dział techniczny:{" "}
    <Link textDecoration="underline" href="tel:+48730427195">
      +48 730 427 195
    </Link>
    <br />
    Magazyn, obsługa platformy:
    <br />
    <Link textDecoration="underline" href="tel:+48570640360">
      +48 570 640 360
    </Link>
  </Text>
);

export default FooterLinks;
