import { MESSAGE_VIEW_DATE } from "../constants/storage";
import useAccessTokenPayload from "./useAccessTokenPayload";

const useMessageStorageKey = () => {
  const { username } = useAccessTokenPayload()!;

  return `${MESSAGE_VIEW_DATE}:${username}`;
};

export default useMessageStorageKey;
