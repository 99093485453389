import { useLocation, useNavigate } from "react-router-dom";

const useHash = () => {
  const { hash, search } = useLocation();
  const navigate = useNavigate();

  return {
    hash: hash.slice(1) || null,
    setHash: (hash: string) => {
      navigate(`${search}#${hash}`);
    },
    removeHash: () => navigate("#"),
  };
};

export default useHash;
