import { FC, useEffect, useRef, useState } from "react";

import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import useDownloadUrl from "../../hooks/useDownloadUrl";
import FileMenu, { IFileMenuProps } from "./FileMenu";
import usePrintUrl from "../../hooks/usePrintUrl";
import { useDeleteDocumentMutation } from "../../redux/api/documentsEndpoints";
import ConfirmDialog from "../common/ConfirmDialog";
import useCanModifyDocs from "../../hooks/useCanModifyDocs";
import type { IDocument } from "../../types/document";

interface IDocPreviewMenuProps extends IFileMenuProps {
  doc: IDocument;
}

const DocPreviewMenu: FC<IDocPreviewMenuProps> = ({
  doc,
  actions = ["print", "download"],
  onAction,
  ...rest
}) => {
  const canModifyDocs = useCanModifyDocs();
  const getDocFileUrl = useGetDocFileUrl();
  const downloadUrl = useDownloadUrl();
  const printUrl = usePrintUrl();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [deleteDoc, { error }] = useDeleteDocumentMutation();

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <>
      <FileMenu
        actions={
          canModifyDocs ? actions : actions.filter((a) => a !== "delete")
        }
        onAction={(action) => {
          if (action === "download") {
            downloadUrl(getDocFileUrl(doc.id), doc.file.filename);
          } else if (action === "print") {
            printUrl(getDocFileUrl(doc.id));
          }
          //  else if (action === "preview") {
          //   setModalOpen(true);
          // }
          else if (action === "delete") {
            setDeleteDialogOpen(true);
          } else {
            onAction?.(action);
          }
        }}
        {...rest}
      />

      {canModifyDocs && (
        <ConfirmDialog
          leastDestructiveRef={cancelRef}
          isOpen={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title="Usuwanie dokumentu"
          confirmLabel="Usuń dokument"
          onConfirm={() => {
            deleteDoc(doc.id);
            onAction?.("delete");
          }}
          onCancel={() => setDeleteDialogOpen(false)}
        >
          Czy jesteś pewny, że chcesz usunąć dokument{" "}
          <b>{doc.file.documentNumber || doc.id}</b>?
        </ConfirmDialog>
      )}
    </>
  );
};

export default DocPreviewMenu;
