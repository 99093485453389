import { useCallback } from "react";
import { selectAccessToken } from "../redux/auth/authSlice";
import { useAppSelector } from "../redux/store";
import { IDocument, IDocumentFile } from "../types/document";

const useGetDocFileUrl = () => {
  const accessToken = useAppSelector(selectAccessToken);
  return useCallback(
    (id: IDocument["id"], fileId?: IDocumentFile["id"]) =>
      fileId
        ? `${process.env.REACT_APP_API_URL}/documents/${id}/files/${fileId}/download?token=${accessToken}`
        : `${process.env.REACT_APP_API_URL}/documents/${id}/download?token=${accessToken}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!!accessToken]
  );
};

export default useGetDocFileUrl;
