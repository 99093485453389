import { useCallback } from "react";
import printJS from "print-js";

const usePrintUrl = () =>
  useCallback(async (url: string, type?: printJS.PrintTypes) => {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error(res.statusText);
    }

    const blob = await res.blob();

    const blobUrl = window.URL.createObjectURL(blob);

    printJS(blobUrl, type);

    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl);
    }, 3000);
  }, []);

export default usePrintUrl;
