import { FC } from "react";
import ReactPaginate, { type ReactPaginateProps } from "react-paginate";

const ArrowRightIcon: FC<{ isRotated?: boolean }> = ({ isRotated }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.583"
    height="15.751"
    viewBox="0 0 8.583 15.751"
    {...(isRotated ? { style: { transform: "rotate(180deg)" } } : {})}
  >
    <g data-name="Group 110">
      <path
        data-name="Line 42"
        transform="rotate(90 3.585 4.292)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M0 7.168 7.168 0"
      />
      <path
        data-name="Line 43"
        transform="rotate(90 0 7.876)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M7.168 7.168 0 0"
      />
    </g>
  </svg>
);

interface PaginateProps
  extends Pick<ReactPaginateProps, "hrefBuilder" | "pageCount"> {
  page: number;
  onPageChange: (page: number) => void;
}

const Paginate: FC<PaginateProps> = ({ page, onPageChange, ...rest }) => (
  <ReactPaginate
    breakLabel="..."
    nextLabel={<ArrowRightIcon />}
    previousLabel={<ArrowRightIcon isRotated />}
    marginPagesDisplayed={1}
    pageRangeDisplayed={4}
    onPageChange={({ selected }) => onPageChange(selected)}
    forcePage={page}
    containerClassName="pagination"
    {...rest}
  />
);

export default Paginate;
