import { SVGProps } from "react";

interface IEyeIconProps extends SVGProps<SVGSVGElement> {
  isOpen?: boolean;
}

const EyeIcon = ({ isOpen = true, ...props }: IEyeIconProps) => {
  if (isOpen) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="currentColor"
        {...props}
      >
        <path d="M16 7.14c-4.907 0-8.742 3.315-11.433 7.73l-.237.399-.195.35-.082.157a.5.5 0 0 0 0 .448l.082.157.12.218.158.274.151.253C7.258 21.545 11.094 24.86 16 24.86c4.907 0 8.742-3.315 11.433-7.73l.152-.254.225-.393.137-.26a.5.5 0 0 0 0-.447l-.082-.157-.12-.218-.158-.274-.151-.253C24.742 10.455 20.906 7.14 16 7.14Zm0 1c4.46 0 8.052 3.105 10.58 7.25l.224.38.129.229-.13.233-.225.38C24.052 20.756 20.46 23.86 16 23.86c-4.46 0-8.052-3.105-10.58-7.25l-.224-.38-.13-.23.131-.232.225-.38C7.948 11.244 11.54 8.14 16 8.14Z" />
        <path d="M16 12.338a3.663 3.663 0 1 0-.001 7.325A3.663 3.663 0 0 0 16 12.338Zm0 1a2.663 2.663 0 1 1 .001 5.325A2.663 2.663 0 0 1 16 13.338Z" />
      </svg>
    );
  }
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="nonzero"
        d="m4.712 2.31.065.062 23.2 26.657a.5.5 0 0 1-.69.719l-.064-.063-5.595-6.428-.195.117C19.8 24.316 17.988 24.86 16 24.86c-3.603 0-6.733-1.869-9.357-4.89a21.09 21.09 0 0 1-1.463-1.894l-.319-.478-.273-.433-.232-.39-.192-.34-.109-.206a.5.5 0 0 1-.036-.364l.034-.089.137-.26.225-.392.152-.254c1.356-2.224 3.002-4.17 4.93-5.552l-5.474-6.29a.5.5 0 0 1 .69-.719Zm5.45 7.773.348-.237c-1.871 1.227-3.488 3.026-4.824 5.12l-.264.421-.225.38-.131.232.063.115.199.343.114.188.124.2.134.21a20.22 20.22 0 0 0 1.698 2.259C9.85 22.137 12.735 23.86 16 23.86c1.797 0 3.452-.504 4.954-1.376l-2.973-3.418a.501.501 0 0 1-.095.073 3.663 3.663 0 0 1-4.658-5.533l-3.066-3.523ZM16 7.14c4.795 0 8.567 3.166 11.25 7.434l.186.3.151.253.158.274.12.218.082.157a.5.5 0 0 1 .034.36l-.034.088-.137.26-.225.392-.152.254c-.893 1.466-1.913 2.81-3.057 3.953l-.662-.76c.949-.953 1.81-2.056 2.58-3.256l.284-.454.225-.38.13-.234-.129-.23-.224-.378C24.052 11.245 20.46 8.14 16 8.14c-.895 0-1.754.125-2.577.358l-.724-.83c1.04-.343 2.14-.528 3.301-.528Zm-2.283 10.232a2.663 2.663 0 0 0 3.604.939l-3.428-3.94a2.664 2.664 0 0 0-.176 3Z"
      />
    </svg>
  );
};

export default EyeIcon;
