import { FC, ReactNode } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from "@chakra-ui/react";

interface DialogProps extends ModalProps {
  title?: ReactNode;
}

const Dialog: FC<DialogProps> = ({ title, children, ...rest }) => (
  <Modal {...rest}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton size="lg" style={{ boxShadow: "none" }} />
      <ModalBody>{children}</ModalBody>
    </ModalContent>
  </Modal>
);

export default Dialog;
