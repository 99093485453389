import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import MobileMenuLinks from "../common/MobileMenuLinks";
import MobileMenuHeader from "../common/MobileMenuHeader";

const MobileMenu: FC = () => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    document.body.classList[isOpen ? "add" : "remove"]("no-scroll");
  }, [isOpen]);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Box
      display={{ base: "flex", lg: "none" }}
      flexDir={"column"}
      h={isOpen ? "calc(100vh - 64px)" : "16"}
      bg="secondary.500"
      color="white"
    >
      <MobileMenuHeader isOpen={isOpen} onClick={() => setOpen(!isOpen)} />

      {isOpen && <MobileMenuLinks />}
    </Box>
  );
};

export default MobileMenu;
