import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import type { SortingState } from "@tanstack/react-table";

import { SORT_BY_PARAM_KEY, SORT_ORDER_PARAM_KEY } from "../constants/params";

const useSortingParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortingState, setSortingState] = useState<SortingState>(() => {
    const sortBy = searchParams.get(SORT_BY_PARAM_KEY) as string;
    const sortOrder = searchParams.get(SORT_ORDER_PARAM_KEY) as string;

    return sortBy && sortOrder
      ? [
          {
            id: sortBy,
            desc: sortOrder === "desc",
          },
        ]
      : [];
  });

  const sortBy = searchParams.get(SORT_BY_PARAM_KEY) || undefined;
  const sortOrder = searchParams.get(SORT_ORDER_PARAM_KEY) || undefined;

  useEffect(() => {
    const [sorting] = sortingState;
    if (sorting) {
      searchParams.set(SORT_BY_PARAM_KEY, sorting.id);
      searchParams.set(SORT_ORDER_PARAM_KEY, sorting.desc ? "desc" : "asc");

      setSearchParams(searchParams);
    } else {
      if (sortBy !== undefined || sortOrder !== undefined) {
        searchParams.delete(SORT_BY_PARAM_KEY);
        searchParams.delete(SORT_ORDER_PARAM_KEY);

        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingState]);

  return {
    sortBy,
    sortOrder,
    sortingState,
    setSortingState,
  };
};

export default useSortingParams;
