import { FC, useEffect, useState } from "react";
import jwtDecode, { type JwtPayload } from "jwt-decode";
import { Flex, Text } from "@chakra-ui/react";

import { useAppSelector } from "../../redux/store";
import { selectAccessToken } from "../../redux/auth/authSlice";

const SessionTimer: FC = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const [expire, setExpire] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const getSeconds = () =>
      Math.max(0, Math.floor(expire - Date.now() / 1000));
    setSeconds(getSeconds);

    const interval = setInterval(() => setSeconds(getSeconds()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [expire]);

  useEffect(() => {
    if (accessToken) {
      try {
        const { exp } = jwtDecode(accessToken) as JwtPayload;
        setExpire(exp!);
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <Flex
      display={{ base: "none", lg: "flex" }}
      direction="column"
      justify="center"
      align="center"
      color="secondary.500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
      >
        <g data-name="Icon feather-clock">
          <path
            d="M33 18A15 15 0 1 1 18 3a15 15 0 0 1 15 15z"
            data-name="Path 43"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-2.5 -2.5)"
          />
          <path
            d="M18 9v9l6 3"
            data-name="Path 44"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-2.5 -2.5)"
          />
        </g>
      </svg>
      <Text fontSize="sm" mt={1}>
        {String(Math.floor(seconds / 60)).padStart(2, "0")}:
        {String(seconds % 60).padStart(2, "0")}
      </Text>
    </Flex>
  );
};

export default SessionTimer;
