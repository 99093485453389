import { FC } from "react";
import { Flex, GridItem, Text, HStack, Button } from "@chakra-ui/react";

import { ISubjectCard } from "../../types/subjectCard";
import { Link } from "react-router-dom";
import PlusIcon from "../../assets/icons/PlusIcon";
import useDesktopQuery from "../../hooks/useDesktopQuery";

interface ISubjectCardProps {
  card: ISubjectCard;
}

const SubjectCard: FC<ISubjectCardProps> = ({
  card: { icon, name, buttons },
}) => {
  const isDesktop = useDesktopQuery();

  return (
    <GridItem
      display="flex"
      flexDir="column"
      alignItems="center"
      py="6"
      px={{ base: "2", lg: "6" }}
      backgroundColor="white"
      rounded="lg"
      boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
    >
      <Flex
        justify="center"
        align="center"
        p="6"
        borderRadius="45px"
        bg="gray.50"
      >
        {icon}
      </Flex>

      <Text fontSize="3xl" fontWeight="medium" my="4" color="5c5c5c">
        {name}
      </Text>

      <HStack spacing="3" justifyContent="center">
        {buttons.map(({ label, href, isPrimary, isAdd }, i) => (
          <Button
            key={i}
            leftIcon={isAdd && !isDesktop ? <PlusIcon /> : undefined}
            as={Link}
            to={href}
            {...(isPrimary
              ? {
                  variant: "outline",
                  colorScheme: "seconadary",
                }
              : { colorScheme: "gray" })}
            {...(isDesktop ? {} : { fontSize: "sm", px: "3" })}
          >
            {isDesktop ? label : label.replace("Dodaj ", "")}
          </Button>
        ))}
      </HStack>
    </GridItem>
  );
};

export default SubjectCard;
