import { FC, ReactNode, useMemo } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  IGetDocumentsQueryFilters,
  useGetDocumentsQuery,
} from "../../redux/api/documentsEndpoints";
import usePageParams from "../../hooks/usePageParams";
import ListingPage from "../layout/ListingPage";
import ListingPagination from "../common/ListingPagination";
import { getPageCountFromMeta } from "../../utils/pagination";
import GenericTable from "../common/table/GenericTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DocumentType, IDocument } from "../../types/document";
import useSortingParams from "../../hooks/useSortingParams";
import LoadingTable from "../common/table/LoadingTable";
import DocumentRowActions from "../docs/DocumentRowActions";
import {
  IFilterCheckboxItem,
  IFilterDateItem,
  IFilterSearchItem,
} from "../../types/filters";
import Filters from "../common/Filters";
import useFiltersParams from "../../hooks/useFiltersParams";
import { Link } from "react-router-dom";
import GoBackIcon from "../../assets/icons/GoBackIcon";
import { UserRole } from "../../types/user";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";

const columnHelper = createColumnHelper<IDocument>();

type DocsSearchFilters = {
  number: IFilterSearchItem;
};

type DocsRestFilters = {
  type?: IFilterCheckboxItem;
  date: IFilterDateItem;
};

interface IDocsProps {
  goBack?: { to: string; label: ReactNode };
  pathPrefix?: string;
  removeNames?: boolean;
  staticFilters?: IGetDocumentsQueryFilters;
}

const Docs: FC<IDocsProps> = ({
  goBack,
  pathPrefix,
  removeNames,
  staticFilters,
}) => {
  const { t } = useTranslation();
  const { sortBy, sortOrder, sortingState, setSortingState } =
    useSortingParams();
  const {
    roles: [role],
    clients,
    constructions,
    access,
  } = useAccessTokenPayload()!;
  const { page, perPage } = usePageParams();

  const searchFilters = useMemo<DocsSearchFilters>(
    () => ({
      number: { type: "search", placeholder: "Wpisz nr dokumentu" },
    }),
    []
  );
  const docTypes = useMemo(
    () =>
      role === UserRole.Head
        ? [
            ...(access?.some(
              (access) => access.type === "invoice" && access.granted
            )
              ? [
                  DocumentType.InvoiceWithAttachment,
                  DocumentType.InvoiceCorrection,
                  // DocumentType.ExportInvoice,
                ]
              : []),
            ...(access?.some(
              (access) => access.type === "protocol" && access.granted
            )
              ? [
                  DocumentType.ReleaseProtocol,
                  DocumentType.LiquidationProtocol,
                  DocumentType.ReturnProtocol,
                ]
              : []),
            ...(access?.some(
              (access) => access.type === "possession" && access.granted
            )
              ? [DocumentType.PossessionState]
              : []),
          ]
        : role === UserRole.Foreman
        ? [
            DocumentType.ReleaseProtocol,
            DocumentType.LiquidationProtocol,
            DocumentType.ReturnProtocol,
            DocumentType.PossessionState,
          ]
        : Object.values(DocumentType),
    [role, access]
  );
  const restFilters = useMemo<DocsRestFilters>(
    () => ({
      ...(docTypes.length > 0
        ? {
            type: {
              type: "checkbox",
              placeholder: "Wszystkie dokumenty",
              options: docTypes.map((type) => ({
                label: t(`documentTypes.${type}`),
                value: type,
              })),
            },
          }
        : {}),
      date: {
        type: "date",
      },
    }),
    [t, docTypes]
  );
  const filters = useMemo(
    () => ({
      ...searchFilters,
      ...restFilters,
    }),
    [searchFilters, restFilters]
  );
  const { values: filterValues, onChange: handleFiltersChange } =
    useFiltersParams(filters);

  const columns = useMemo<ColumnDef<IDocument, any>[]>(
    () => [
      ...(removeNames
        ? []
        : [
            ...([
              UserRole.SuperAdmin,
              UserRole.Admin,
              UserRole.Employee,
            ].includes(role) ||
            (role === UserRole.Manager && clients.length > 1)
              ? [
                  columnHelper.accessor(
                    (row) => row.clientName || row.file.clientId,
                    {
                      id: "client_id",
                      header: () => "Kontrahent",
                      cell: (info) => <Text>{info.renderValue()}</Text>,
                    }
                  ),
                ]
              : []),
            ...([
              UserRole.SuperAdmin,
              UserRole.Admin,
              UserRole.Employee,
              UserRole.Manager,
            ].includes(role) ||
            (role === UserRole.Head && constructions.length > 1)
              ? [
                  columnHelper.accessor(
                    (row) => row.constructionName || row.file.constructionId,
                    {
                      id: "construction_id",
                      header: () => "Obiekt",
                      cell: (info) => <Text>{info.renderValue()}</Text>,
                    }
                  ),
                ]
              : []),
          ]),
      columnHelper.accessor((row) => row.file.documentNumber || "", {
        id: "document_number",
        header: () => "Nr dok.",
        cell: (info) => <Text>{info.renderValue() || "-"}</Text>,
      }),
      columnHelper.accessor((row) => row.file.createdAt.split(" ").shift(), {
        id: "document_date",
        header: () => "Data",
        cell: (info) => <Text>{info.renderValue()}</Text>,
      }),
      columnHelper.accessor((row) => row.file.documentType, {
        id: "document_type",
        header: () => "Dokumenty",
        cell: (info) => <Text>{t(`documentTypes.${info.getValue()}`)}</Text>,
      }),
      columnHelper.accessor((row) => row.id, {
        id: "actions",
        header: () => "",
        cell: ({ row }) => (
          <DocumentRowActions doc={row.original} pathPrefix={pathPrefix} />
        ),
      }),
    ],
    [t, pathPrefix, removeNames, role, clients.length, constructions.length]
  );

  const { data: getDocumentsData, isFetching } = useGetDocumentsQuery({
    page,
    perPage,
    ...(sortBy && sortOrder
      ? {
          sortBy,
          sortOrder,
        }
      : {
          sortBy: "document_date",
          sortOrder: "desc",
        }),
    ...(staticFilters || {}),
    ...(filterValues.number
      ? { "filters[document_number]": filterValues.number }
      : {}),
    ...(filterValues.type
      ? { "filters[document_type]": filterValues.type }
      : {}),
    ...(filterValues.date
      ? filterValues.date.includes(",")
        ? {
            "filters[document_date_from]": filterValues.date.split(",")[0],
            "filters[document_date_to]": filterValues.date.split(",")[1],
          }
        : {
            "filters[document_date]": filterValues.date,
          }
      : {}),
  });

  const pageCount = getPageCountFromMeta(getDocumentsData?.meta);

  return (
    <>
      <ListingPage
        isFetching={!!getDocumentsData && isFetching}
        search={
          <Filters
            filters={searchFilters}
            values={filterValues}
            // @ts-ignore
            onChange={handleFiltersChange}
          />
        }
        filters={
          <Filters
            filters={restFilters}
            values={filterValues}
            // @ts-ignore
            onChange={handleFiltersChange}
          />
        }
      >
        {goBack && (
          <HStack as={Link} to={goBack.to} spacing="1" my="-2.5" px="4">
            <GoBackIcon />
            <Text fontWeight="medium">{goBack.label}</Text>
          </HStack>
        )}

        <Box flex="1" w="full" px={{ base: "0", lg: "4" }}>
          {getDocumentsData?.data ? (
            <GenericTable
              data={getDocumentsData.data}
              columns={columns}
              sortingState={sortingState}
              setSortingState={setSortingState}
            />
          ) : (
            <LoadingTable />
          )}
        </Box>

        <ListingPagination pageCount={pageCount} />
      </ListingPage>
    </>
  );
};

export default Docs;
