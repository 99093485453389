import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, HStack } from "@chakra-ui/react";

import { useDeleteClientMutation } from "../../redux/api/clientsEndpoints";
import Dialog from "./Dialog";
import EditClientForm from "./EditClientForm";
import ConfirmDialog from "./ConfirmDialog";
import type { IClient } from "../../types/client";

interface IClientRowActions {
  client: IClient;
  canEdit?: boolean;
}

const ClientRowActions = ({ client, canEdit }: IClientRowActions) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [deleteClient, { isLoading, isSuccess, originalArgs }] =
    useDeleteClientMutation();

  const isDeleting = originalArgs === client.id && isLoading;
  const isDeleted = originalArgs === client.id && isSuccess;

  useEffect(() => {
    if (isDeleting || isDeleted) {
      setDeleteDialogOpen(false);
    }
  }, [isDeleting, isDeleted]);

  return (
    <>
      <HStack spacing={4} justifyContent="flex-end">
        {canEdit && (
          <>
            <Button
              ref={cancelRef}
              variant="ghost"
              colorScheme="secondary"
              onClick={() => setDeleteDialogOpen(true)}
              size="sm"
              isLoading={isDeleting}
              isDisabled={isDeleted}
            >
              Usuń
            </Button>
            <Button
              variant="outline"
              colorScheme="secondary"
              onClick={() => setEditDialogOpen(true)}
              size="sm"
              isDisabled={isDeleting || isDeleted}
            >
              Edytuj
            </Button>
          </>
        )}

        <Button
          variant="outline"
          colorScheme="secondary"
          onClick={() =>
            navigate(`/clients/${client.id}`, {
              state: { prevLocation: location },
            })
          }
          size="sm"
          isDisabled={isDeleting || isDeleted}
        >
          Przejdź
        </Button>
      </HStack>

      {canEdit && (
        <>
          <Dialog
            isOpen={isEditDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            title="Edytuj kontrahenta"
          >
            <EditClientForm
              client={client}
              onClose={() => setEditDialogOpen(false)}
            />
          </Dialog>

          <ConfirmDialog
            leastDestructiveRef={cancelRef}
            isOpen={isDeleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            title="Usuwanie kontrahenta"
            confirmLabel="Usuń kontrahenta"
            onConfirm={() => deleteClient(client.id)}
            onCancel={() => setDeleteDialogOpen(false)}
          >
            Czy jesteś pewny, że chcesz usunąć kontrahenta <b>{client.name}</b>?
          </ConfirmDialog>
        </>
      )}
    </>
  );
};

export default ClientRowActions;
