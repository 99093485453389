import type { IConstruction } from "./construction";

export enum DocumentType {
  InvoiceWithAttachment = "InvoiceWithAttachment",
  InvoiceCorrection = "InvoiceCorrection",
  // ExportInvoice = "ExportInvoice",
  ReleaseProtocol = "ReleaseProtocol",
  LiquidationProtocol = "LiquidationProtocol",
  ReturnProtocol = "ReturnProtocol",
  PossessionState = "PossessionState",
}

export interface IDocument {
  id: string;
  attachedAt: string;
  deletedAt?: string;
  clientName?: string;
  constructionName?: string;
  file: {
    warehouseId: string;
    clientId: string;
    documentNumber: string;
    constructionId: IConstruction["id"];
    documentType: DocumentType;
    createdAt: string;
    filename: string;
  };
}

export interface IDocumentFile {
  id: string;
  filename: string;
  attachedAt: string;
}
