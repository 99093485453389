import { SVGProps } from "react";

const PenIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#002765"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          fill="#EBEBEB"
          fillRule="nonzero"
          d="M49.63 21.889 77.371 49.63 89.26 37.741 61.519 10z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M73.408 45.667 67.464 75.39 10 89.26l13.871-57.463 29.722-5.945z"
        />
        <path d="m10 89.26 30.063-30.063M53.593 53.593a7.926 7.926 0 1 0-15.852 0 7.926 7.926 0 0 0 15.852 0Z" />
      </g>
    </svg>
  );
};

export default PenIllustration;
