import { useCallback } from "react";
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import { format } from "date-fns";

import DatePicker from "./DatePicker";
import SearchIcon from "../../assets/icons/SearchIcon";
import XIcon from "../../assets/icons/XIcon";
import PopoverButton from "./PopoverButton";
import CheckboxList from "./CheckboxList";
import type { TFilters } from "../../types/filters";

interface FiltersProps<T extends TFilters> {
  filters: T;
  values: Record<keyof T, any>;
  onChange: (newValues: Record<keyof T, any>) => void;
}

const Filters = <T extends TFilters>({
  filters,
  values,
  onChange,
}: FiltersProps<T>) => {
  const onChangeFilter = useCallback(
    (key: string, value: any) => {
      onChange({
        ...values,
        [key]: value,
      });
    },
    [values, onChange]
  );

  return (
    <HStack spacing="2.5" flexWrap="wrap">
      {Object.keys(filters).map((key) => {
        const filterItem = filters[key];

        return (
          <Box key={key}>
            {filterItem.type === "search" ? (
              <InputGroup>
                <InputLeftElement>
                  <SearchIcon />
                </InputLeftElement>
                <Input
                  placeholder={filterItem.placeholder}
                  htmlSize={
                    filterItem.placeholder
                      ? filterItem.placeholder.length + 2
                      : undefined
                  }
                  value={values[key]}
                  onChange={(e) => onChangeFilter(key, e.target.value)}
                />
                {values[key] && (
                  <InputRightElement>
                    <XIcon
                      role="button"
                      onClick={() => onChangeFilter(key, undefined)}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            ) : filterItem.type === "select" ? (
              <Select
                placeholder={filterItem.placeholder}
                value={values[key]}
                onChange={(e) => onChangeFilter(key, e.target.value)}
              >
                {filterItem.values.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            ) : filterItem.type === "date" ? (
              <DatePicker
                dates={values[key] ? values[key].split(",") : undefined}
                onChange={(dates) =>
                  onChangeFilter(
                    key,
                    dates?.length
                      ? dates.map((date) => format(date, "yyyy-MM-dd"))
                      : undefined
                  )
                }
              />
            ) : filterItem.type === "abc" ? (
              <Popover placement="bottom-start">
                {({ isOpen, onClose }) => (
                  <>
                    <PopoverTrigger>
                      <PopoverButton
                        isOpen={isOpen}
                        isFilled={!!values[key]}
                        onReset={() => onChangeFilter(key, undefined)}
                      >
                        {values[key] || "Abc"}
                      </PopoverButton>
                    </PopoverTrigger>
                    <PopoverContent p="6">
                      <SimpleGrid columns={7} gap="4">
                        {[
                          ...new Array(90 - 65 + 1)
                            .fill(null)
                            .map((_, i) => String.fromCharCode(i + 65)),
                          "#",
                        ].map((char) => (
                          <Box
                            key={char}
                            role="button"
                            onClick={() => {
                              onChangeFilter(
                                key,
                                char === values[key] ? undefined : char
                              );
                              onClose();
                            }}
                            fontWeight={
                              char === values[key] ? "bold" : "normal"
                            }
                          >
                            {char}
                          </Box>
                        ))}
                      </SimpleGrid>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            ) : filterItem.type === "checkbox" ? (
              <Popover placement="bottom-start">
                {({ isOpen, onClose }) => {
                  const checkboxValues: string[] =
                    values[key]?.split(",").filter((v: string) => !!v) || [];

                  return (
                    <>
                      <PopoverTrigger>
                        <PopoverButton
                          isOpen={isOpen}
                          isFilled={!!values[key]}
                          onReset={() => onChangeFilter(key, undefined)}
                          maxW="240px"
                        >
                          {checkboxValues.length > 0
                            ? checkboxValues
                                .map(
                                  (v) =>
                                    filterItem.options.find(
                                      (option) => option.value === v
                                    )?.label
                                )
                                .filter((label) => !!label)
                                .join(", ")
                            : filterItem.placeholder}
                        </PopoverButton>
                      </PopoverTrigger>

                      <PopoverContent p="4" minW="200px">
                        <CheckboxList
                          options={filterItem.options}
                          values={values[key] ? values[key].split(",") : []}
                          onChange={(vals) => {
                            onChangeFilter(key, vals.join(","));
                            onClose();
                          }}
                          onClose={onClose}
                        />
                      </PopoverContent>
                    </>
                  );
                }}
              </Popover>
            ) : null}
          </Box>
        );
      })}
    </HStack>
  );
};

export default Filters;
