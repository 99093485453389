import { forwardRef } from "react";
import { Center, HStack, type CenterProps, Text } from "@chakra-ui/react";
import XIcon from "../../assets/icons/XIcon";
import ChevronIcon from "./ChevronIcon";

interface IPopoverButtonProps extends CenterProps {
  isOpen?: boolean;
  isFilled?: boolean;
  onReset?: () => void;
}

const PopoverButton = forwardRef(
  (
    { isOpen, isFilled, onReset, children, ...rest }: IPopoverButtonProps,
    ref
  ) => (
    <Center
      ref={ref}
      role="button"
      px="3"
      h="10"
      rounded="md"
      bg="gray.100"
      _hover={{
        bg: "gray.400",
      }}
      {...(isOpen || isFilled
        ? {
            border: "1px solid",
            borderColor: "secondary.500",
          }
        : {})}
      {...rest}
    >
      <Text
        as="span"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {children}
      </Text>

      <HStack spacing="2" ml="2.5" mr="-1">
        {isFilled && onReset && (
          <XIcon
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              onReset();
            }}
          />
        )}
        <ChevronIcon isActive={isOpen} />
      </HStack>
    </Center>
  )
);

export default PopoverButton;
