import { useEffect, useRef, useState } from "react";
import { Button, HStack } from "@chakra-ui/react";

import Dialog from "./Dialog";
import { useDeleteUserMutation } from "../../redux/api/usersEndpoints";
import { UserRole, type IUser } from "../../types/user";
import EditUserForm from "../users/EditUserForm";
import ConfirmDialog from "./ConfirmDialog";

interface IUserRowActions {
  user: IUser;
}

const UserRowActions = ({ user }: IUserRowActions) => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const canDelete = !user.roles.includes(UserRole.SuperAdmin);
  const [deleteUser, { isLoading, isSuccess, originalArgs }] =
    useDeleteUserMutation();
  const isDeleting = originalArgs === user.id && isLoading;
  const isDeleted = originalArgs === user.id && isSuccess;

  useEffect(() => {
    if (isDeleting || isDeleted) {
      setDeleteDialogOpen(false);
    }
  }, [isDeleting, isDeleted]);

  return (
    <>
      <HStack spacing={4} justifyContent="flex-end">
        {canDelete && (
          <Button
            ref={cancelRef}
            variant="ghost"
            colorScheme="secondary"
            onClick={() => setDeleteDialogOpen(true)}
            size="sm"
            isLoading={isDeleting}
            isDisabled={isDeleted}
          >
            Usuń
          </Button>
        )}
        <Button
          variant="outline"
          colorScheme="secondary"
          onClick={() => setEditDialogOpen(true)}
          size="sm"
          isDisabled={isDeleting || isDeleted}
        >
          Edytuj
        </Button>
      </HStack>

      <Dialog
        isOpen={isEditDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        title="Edytuj użytkownika"
      >
        <EditUserForm user={user} onClose={() => setEditDialogOpen(false)} />
      </Dialog>

      {canDelete && (
        <ConfirmDialog
          leastDestructiveRef={cancelRef}
          isOpen={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title="Usuń użytkownika"
          confirmLabel="Usuń użytkownika"
          onConfirm={() => deleteUser(user.id)}
          onCancel={() => setDeleteDialogOpen(false)}
        >
          Czy jesteś pewny, że chcesz usunąć użytkownika <b>{user.name}</b>?
        </ConfirmDialog>
      )}
    </>
  );
};

export default UserRowActions;
