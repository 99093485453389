import { SVGProps } from "react";

const UsersIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" {...props}>
      <g fill="none" fillRule="nonzero" stroke="#002765">
        <path
          fill="#EBEBEB"
          d="M62.452 50.564c9.82 0 17.782-7.961 17.782-17.782 0-9.82-7.96-17.782-17.781-17.782-9.82 0-17.782 7.96-17.783 17.781.002 9.82 7.962 17.78 17.782 17.783Zm15.806 3.952h-6.8a21.5 21.5 0 0 1-18 0h-6.8a15.805 15.805 0 0 0-15.819 15.806v1.977a5.929 5.929 0 0 0 5.927 5.927h51.373a5.929 5.929 0 0 0 5.926-5.927v-1.977a15.807 15.807 0 0 0-15.807-15.807v.001Z"
        />
        <path
          fill="#FFF"
          d="M37.613 57.338c9.82 0 17.782-7.961 17.782-17.782 0-9.82-7.96-17.782-17.781-17.782-9.82 0-17.782 7.96-17.783 17.781.002 9.82 7.962 17.78 17.782 17.783Zm15.806 3.952h-6.8a21.5 21.5 0 0 1-18 0h-6.8A15.805 15.805 0 0 0 6 77.096v1.977A5.929 5.929 0 0 0 11.927 85H63.3a5.929 5.929 0 0 0 5.926-5.927v-1.977a15.807 15.807 0 0 0-15.807-15.807v.001Z"
        />
      </g>
    </svg>
  );
};

export default UsersIllustration;
