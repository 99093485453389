import { Flex, Text } from "@chakra-ui/react";

interface ILetterAvatarProps {
  letters?: string;
}

const LetterAvatar = ({ letters = "AZ" }: ILetterAvatarProps) => {
  return (
    <Flex
      justify="center"
      align="center"
      border="1px"
      borderColor="secondary.500"
      borderRadius="full"
      width={{ base: "10", lg: "12" }}
      height={{ base: "10", lg: "12" }}
    >
      <Text title={letters} fontSize="xl" color="secondary.500">
        {(letters.split(" ").length > 1
          ? letters
              .split(" ")
              .slice(0, 2)
              .map((n) => n[0])
              .join("")
          : letters.slice(0, 2)
        ).toUpperCase()}
      </Text>
    </Flex>
  );
};

export default LetterAvatar;
