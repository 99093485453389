import { FC } from "react";
import { Flex, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import MessageDialogProvider from "../providers/MessageDialogProvider";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import FooterLinks from "../common/FooterLinks";

const DashboardLayout: FC = () => (
  <>
    <Flex minH="100vh" direction="column">
      <Navbar />
      <Sidebar />
      <Flex
        paddingLeft={{ base: "0", lg: "17rem" }}
        py="1.875rem"
        flex="1"
        overflow="hidden"
        direction="column"
      >
        <Outlet />
      </Flex>
      <VStack
        display={{ base: "flex", lg: "none" }}
        spacing="1.5"
        p="4"
        align="center"
        bg="gray.400"
        textAlign="center"
      >
        <FooterLinks />
      </VStack>
    </Flex>

    <MessageDialogProvider />
  </>
);

export default DashboardLayout;
