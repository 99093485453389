import { FC, useRef, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import DocIcon from "../../assets/icons/DocIcon";
import XIcon from "../../assets/icons/XIcon";
import { useUploadDocumentFileMutation } from "../../redux/api/documentsEndpoints";
import type { IDocument } from "../../types/document";

interface IDocFilesUploaderProps extends BoxProps {
  docId: IDocument["id"];
}

const DocFilesUploader: FC<IDocFilesUploaderProps> = ({ docId, ...rest }) => {
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [uploadFile, { isLoading }] = useUploadDocumentFileMutation();

  const handleSave = async () => {
    try {
      for (const file of files) {
        await uploadFile({ id: docId, filename: file.name, data: file });
      }
      setFiles([]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        multiple
        onClick={(e) => {
          e.currentTarget.value = "";
        }}
        onChange={(e) => {
          if (e.target.files) {
            setFiles([...files, ...Array.from(e.target.files)]);
          }
        }}
        style={{ display: "none" }}
      />

      <Box
        p="3"
        bg="#efefef"
        border="dashed 1px #002765;"
        rounded="18px"
        onDrop={(e) => {
          e.preventDefault();

          if (isLoading) {
            return;
          }

          let itemFiles: File[] = [];
          if (e.dataTransfer.items) {
            for (const item of Array.from(e.dataTransfer.items)) {
              if (item.kind === "file") {
                const file = item.getAsFile();
                if (file) {
                  itemFiles = [...itemFiles, file];
                }
              }
            }
          }

          if (itemFiles.length > 0) {
            setFiles([...files, ...itemFiles]);
          }
        }}
        onDragOver={(e) => e.preventDefault()}
        {...rest}
      >
        <Text color="#4e4e4e">
          Aby dołączyć pliki, przesuń i upuść pliki w tym oknie, lub{" "}
          <Text
            as="span"
            role="button"
            onClick={() => inputRef.current?.click()}
            color="#002765"
            textDecor="underline"
          >
            wybierz pliki z komputera
          </Text>
        </Text>

        {files.length > 0 && (
          <Wrap mt="8" mb="3" spacingX="10" spacingY="6" justify="center">
            {files.map((file, i) => (
              <WrapItem key={i}>
                <HStack spacing="2.5" color="#c4c4c4">
                  <DocIcon />
                  <Text
                    maxW="160px"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    color="secondary.500"
                  >
                    {file.name}
                  </Text>
                  <XIcon
                    role="button"
                    onClick={() =>
                      setFiles(files.slice(0, i).concat(files.slice(i + 1)))
                    }
                  />
                </HStack>
              </WrapItem>
            ))}
          </Wrap>
        )}
      </Box>

      {files.length > 0 && (
        <HStack spacing="2" mt="5">
          <Button
            colorScheme="secondary"
            variant="outline"
            onClick={() => handleSave()}
            isLoading={isLoading}
          >
            Zapisz
          </Button>
          <Button
            type="button"
            colorScheme="secondary"
            variant="ghost"
            fontWeight="medium"
            onClick={() => setFiles([])}
          >
            Anuluj
          </Button>
        </HStack>
      )}
    </>
  );
};

export default DocFilesUploader;
