import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Box,
  Flex,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import useDesktopQuery from "../../../hooks/useDesktopQuery";

interface IGenericTableProps<T> {
  data?: T[];
  columns: ColumnDef<T, string>[];
  sortingState: SortingState;
  setSortingState: OnChangeFn<SortingState>;
  emptyStateText?: string;
}

const GenericTable = <T extends any>({
  data = [],
  columns,
  sortingState,
  setSortingState,
  emptyStateText = "Lista jest pusta",
}: IGenericTableProps<T>) => {
  const isDesktop = useDesktopQuery();
  const isEmpty = data.length === 0;

  const table = useReactTable({
    data,
    columns,
    manualSorting: true,
    state: {
      sorting: sortingState,
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <TableContainer
        {...(isDesktop
          ? {
              border: "1px solid",
              rounded: "xl",
            }
          : { borderBottom: "1px solid" })}
        borderColor="gray.400"
      >
        <Table
          variant="unstyled"
          {...(isDesktop
            ? {}
            : {
                roundedTop: "xl",
                overflow: "hidden",
                borderBottom: "1px solid red",
              })}
        >
          <Thead bg="gray.400">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers?.map((header) => (
                  <Th key={header.id} fontSize="md">
                    {header.isPlaceholder ? null : (
                      <Box
                        {...(header.column.getCanSort() && !isEmpty
                          ? {
                              onClick: header.column.getToggleSortingHandler(),
                              role: "button",
                            }
                          : {})}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ▲",
                          desc: " ▼",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Box>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                borderBottom="solid 1px"
                borderColor="gray.400"
                _last={isDesktop ? { border: "0" } : {}}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} py="2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}

            {isEmpty && (
              <Tr>
                <Td colSpan={columns.length}>
                  <Flex justify="center">
                    <Text>{emptyStateText}</Text>
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GenericTable;
