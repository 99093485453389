import { FC, SVGProps } from "react";

export interface IconBaseProps extends SVGProps<SVGSVGElement> {
  size: string | number;
}

const IconBase: FC<IconBaseProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} {...rest} />
);

export default IconBase;
