import { FC, ChangeEvent } from "react";
import { Flex, Select } from "@chakra-ui/react";

import Paginate from "./Paginate";
import { PAGINATION_PER_PAGE_COUNTS } from "../../constants/pagination";
import usePageParams from "../../hooks/usePageParams";

const SelectIcon: FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="10"
    viewBox="0 0 12 10"
  >
    <g fill="none">
      <path
        d="M6 1.944 1.766 9h8.468L6 1.944M6 0l6 10H0L6 0z"
        fill="currentColor"
        stroke="none"
        transform="rotate(180 6 5)"
      />
    </g>
  </svg>
);

interface IListingPaginationProps {
  pageCount: number;
}

const ListingPagination: FC<IListingPaginationProps> = ({ pageCount }) => {
  const { page, setPage, perPage, setPerPage } = usePageParams();

  const handlePerPageSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPerPage(e.target.value);
  };

  const handleCurrentPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent={{ base: "center", lg: "end" }}
      px={{ lg: "4" }}
    >
      <Paginate
        page={page - 1}
        pageCount={pageCount}
        onPageChange={handleCurrentPageChange}
      />
      <Select
        value={perPage}
        onChange={handlePerPageSelectChange}
        variant="outline"
        roundedLeft="0px"
        size="sm"
        w="auto"
        icon={<SelectIcon />}
      >
        {PAGINATION_PER_PAGE_COUNTS.map((perPage) => (
          <option key={perPage} value={perPage}>
            {perPage}
          </option>
        ))}
      </Select>
    </Flex>
  );
};

export default ListingPagination;
