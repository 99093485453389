import { SVGProps } from "react";

const DocsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="nonzero"
        d="M14.6 3v7.1l.008.09a.5.5 0 0 0 .402.402l.09.008h7.1v13c0 .727-.556 1.326-1.265 1.394L20.8 25H6.4a1.402 1.402 0 0 1-1.394-1.265L5 23.6V4.4c0-.727.556-1.326 1.265-1.394L6.4 3h8.2ZM19 19.5H8.2v1H19v-1Zm0-4.8H8.2v1H19v-1Zm-7.586-4.8h-2.7v1h2.7v-1ZM22.7 9.6h-7.1V2.5l7.1 7.1Z"
      />
    </svg>
  );
};

export default DocsIcon;
