import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, type SubmitHandler } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";

import useErrorMsg from "../../hooks/useErrorMsg";
import {
  useCreateConstructionMutation,
  useUpdateConstructionMutation,
} from "../../redux/api/constructionsEndpoints";
import type { IClient } from "../../types/client";
import type { IConstruction } from "../../types/construction";
import { useGetClientsQuery } from "../../redux/api/clientsEndpoints";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface IEditConstructionFormValues {
  id: IConstruction["id"];
  name: string;
  clientId: IClient["id"];
}

interface IEditConstructionFormProps {
  clientId?: IClient["id"];
  construction?: IConstruction;
  onClose: () => void;
}

const EditConstructionForm: FC<IEditConstructionFormProps> = ({
  clientId,
  construction,
  onClose,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IEditConstructionFormValues>({
    defaultValues: {
      id: construction?.id || "",
      name: construction?.name || "",
      clientId: clientId || "",
    },
  });

  const { data: getClientsData } = useGetClientsQuery(
    clientId
      ? skipToken
      : { page: 1, perPage: 500, sortBy: "name", sortOrder: "asc" }
  );

  const [createConstruction, createState] = useCreateConstructionMutation();
  const [updateConstruction, updateState] = useUpdateConstructionMutation();

  const isLoading = createState.isLoading || updateState.isLoading;
  const isSuccess = createState.isSuccess || updateState.isSuccess;
  const error = createState.error || updateState.error;
  const errorMsg = useErrorMsg(error);
  const noRelationError = errorMsg === "Construction does not belong to client";

  const onSubmit: SubmitHandler<IEditConstructionFormValues> = ({
    id,
    clientId,
    ...values
  }) => {
    if (construction) {
      updateConstruction({
        ...construction,
        ...values,
      });
    } else {
      createConstruction({ id, clientId, ...values });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (noRelationError) {
      setError("id", {
        message: "ID obiektu nie jest powiązane z wybranym kontrahentem",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noRelationError]);

  return (
    <Box as="form" w="full" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4">
        {errorMsg && !noRelationError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{errorMsg}</AlertTitle>
          </Alert>
        )}

        {!clientId && (
          <FormControl isInvalid={!!errors.clientId}>
            <FormLabel>{t("common.forms.selectClientName.label")}</FormLabel>
            <Select
              size="lg"
              placeholder={
                t("common.forms.selectClientName.placeholder") as string
              }
              {...register("clientId", { required: true })}
            >
              {getClientsData?.data.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </Select>

            {errors.clientId && (
              <FormErrorMessage>
                {t(
                  `common.forms.selectClientName.${
                    errors.clientId.type || "validate"
                  }`
                )}
              </FormErrorMessage>
            )}
          </FormControl>
        )}

        <FormControl isInvalid={!!errors.name}>
          <FormLabel>{t("common.forms.constructionName.label")}</FormLabel>
          <Input
            size="lg"
            placeholder={
              t("common.forms.constructionName.placeholder") as string
            }
            {...register("name", {
              required: true,
            })}
          />

          {errors.name && (
            <FormErrorMessage>
              {t(
                `common.forms.constructionName.${
                  errors.name.type || "validate"
                }`
              )}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.id}>
          <FormLabel>{t("common.forms.constructionId.label")}</FormLabel>
          <Input
            size="lg"
            type="number"
            step={0}
            maxLength={9}
            placeholder={t("common.forms.constructionId.placeholder") as string}
            onKeyDown={(e) => {
              if (
                !/\d/.test(e.key) &&
                !/^Arrow/.test(e.key) &&
                e.key !== "Backspace" &&
                !e.metaKey
              ) {
                e.preventDefault();
              }
            }}
            {...register("id", {
              required: true,
              minLength: 9,
              maxLength: 9,
            })}
          />

          {errors.id && (
            <FormErrorMessage>
              {errors.id?.message ||
                t(
                  `common.forms.constructionId.${errors.id.type || "validate"}`
                )}
            </FormErrorMessage>
          )}
        </FormControl>
      </VStack>

      <HStack spacing="2" mt="6">
        <Button
          type="submit"
          colorScheme="secondary"
          variant="outline"
          isLoading={isLoading}
        >
          Zapisz
        </Button>
        <Button
          type="button"
          colorScheme="secondary"
          variant="ghost"
          fontWeight="medium"
          onClick={() => onClose()}
        >
          Anuluj
        </Button>
      </HStack>
    </Box>
  );
};

export default EditConstructionForm;
