import { api } from "./index";
import { TAG_LIST_SYMBOL } from "../../constants/redux";
import type {
  IPaginatedReponse,
  IPaginatedRequestQueryParams,
} from "../../types/api";
import type { IUser, UserRole } from "../../types/user";

type TGetUsersQueryParams = IPaginatedRequestQueryParams & {
  ["filters[username]"]?: string;
  ["filters[email]"]?: string;
  ["filters[role]"]?: string;
};

export const usersEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<IPaginatedReponse<IUser>, TGetUsersQueryParams>({
      query: (params) =>
        `/users?${new URLSearchParams(
          params as unknown as Record<string, string>
        ).toString()}`,
      providesTags: (result) =>
        result
          ? [
              { id: TAG_LIST_SYMBOL, type: "User" },
              ...result.data.map((user) => ({
                id: user.id,
                type: "User" as const,
              })),
            ]
          : [],
    }),
    createUser: builder.mutation<
      void,
      Omit<IUser, "id" | "roles"> & { role: UserRole; password: string }
    >({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: "User", id: TAG_LIST_SYMBOL }],
    }),
    getUser: builder.query<IUser, IUser["id"]>({
      query: (id) => `/users/${id}`,
      providesTags: (user) => (user ? [{ type: "User", id: user.id }] : []),
    }),
    updateUser: builder.mutation<
      IUser,
      Pick<IUser, "id"> & Partial<Omit<IUser, "id">> & { password?: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              { type: "User", id: TAG_LIST_SYMBOL },
              { type: "User", id },
            ],
    }),
    deleteUser: builder.mutation<void, IUser["id"]>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        error
          ? []
          : [
              { type: "User", id: TAG_LIST_SYMBOL },
              { type: "User", id },
            ],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersEndpoints;
