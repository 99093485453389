import { FC, useEffect, useMemo, useRef, useState } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { HStack, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import XIcon from "../../assets/icons/XIcon";
import ChevronIcon from "./ChevronIcon";

interface IDatePickerProps {
  dates?: string[];
  onChange: (dates: Date[] | undefined) => void;
}

const DatePicker: FC<IDatePickerProps> = ({ dates, onChange }) => {
  const groupRef = useRef<HTMLDivElement | null>(null);
  const [isExpanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const selectedDates = useMemo(
    () => (dates ? dates.map((date) => new Date(date)) : []),
    [dates]
  );
  const isFilled = selectedDates.length >= 2;

  useEffect(() => {
    try {
      const inputEl = groupRef.current?.querySelector(
        "input"
      ) as HTMLInputElement;

      if (inputEl) {
        const setAttribute = inputEl.setAttribute;

        inputEl.setAttribute = (key: string, value: string) => {
          if (key === "aria-expanded") {
            setExpanded(value === "true");
          }

          setAttribute.call(inputEl, key, value);
        };

        return () => {
          inputEl.setAttribute = setAttribute;
        };
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <InputGroup ref={groupRef} zIndex="5">
      <RangeDatepicker
        selectedDates={selectedDates}
        onDateChange={onChange}
        propsConfigs={{
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              p: 0,
              rounded: "5px",
              _hover: {
                bg: "gray.400",
              },
            },
            selectedBtnProps: {
              bg: "gray.400",
              fontWeight: "medium",
            },
            isInRangeBtnProps: {
              bg: "gray.300",
            },
            todayBtnProps: {
              bg: "gray.300",
            },
          },
          inputProps: {
            placeholder: t("filters.date.placeholder") as string,
            _placeholder: { color: "secondary.500" },
            width: isFilled
              ? "262px"
              : selectedDates.length === 1
              ? "142px"
              : "90px",
            minW: "auto",
            pr: 8,
            ...(isFilled || isExpanded
              ? {
                  borderColor: "secondary.500",
                  border: "1px solid",
                }
              : {}),
            style: {
              caretColor: "transparent",
            },
          },
          popoverCompProps: {
            popoverBodyProps: {
              borderColor: "red",
            },
            popoverContentProps: {
              border: "none",
              rounded: "5px",
              bg: "gray.100",
              shadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            },
          },
          dateHeadingProps: {
            fontWeight: "regular",
          },
        }}
        configs={{
          dayNames: t("common.dayNames", { returnObjects: true }) as string[],
          monthNames: t("common.monthNames", {
            returnObjects: true,
          }) as string[],
          firstDayOfWeek: 1,
          dateFormat: "dd.MM.yyyy",
        }}
      />
      <InputRightElement
        onClick={(e) => {
          e.stopPropagation();

          try {
            groupRef.current?.querySelector("input")?.click();
          } catch (err) {
            console.error(err);
          }
        }}
        w={isFilled ? "16" : "10"}
      >
        <HStack spacing="2">
          {isFilled && (
            <XIcon
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                onChange(undefined);
              }}
            />
          )}
          <ChevronIcon isActive={isExpanded} />
        </HStack>
      </InputRightElement>
    </InputGroup>
  );
};

export default DatePicker;
