import { VStack } from "@chakra-ui/react";
import { FC } from "react";

import Notifications from "../base/Notifications";
import SubjectCards from "../common/SubjectCards";

const Dashboard: FC = () => {
  return (
    <VStack spacing="4" alignItems="stretch" w="full" px="4">
      <Notifications />
      <SubjectCards />
    </VStack>
  );
};

export default Dashboard;
