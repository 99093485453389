import { FC, MouseEvent, useRef, useState } from "react";
import {
  Box,
  Image,
  Center,
  AspectRatio,
  Flex,
  type BoxProps,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";

import { type IDocumentFile } from "../../types/document";
import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import DocFilePreviewMenu from "./DocFilePreviewMenu";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";
import { UserRole } from "../../types/user";

export interface IDocFilePreviewItemProps extends BoxProps {
  docId: string;
  file: IDocumentFile;
  showPreview: (id: IDocumentFile["id"]) => void;
}

const DocFilePreviewItem: FC<IDocFilePreviewItemProps> = ({
  docId,
  file,
  showPreview,
  ...rest
}) => {
  const {
    roles: [role],
  } = useAccessTokenPayload()!;
  const menuElRef = useRef<HTMLDivElement | null>(null);
  const [isMenuActive, setMenuActive] = useState(false);
  const getDocFileUrl = useGetDocFileUrl();
  const cancelRef = useRef<HTMLDivElement>(null);
  const fileUrl = getDocFileUrl(docId, file.id);

  return (
    <>
      <AspectRatio ratio={180 / 156} {...rest}>
        <Flex
          role="button"
          onClick={(e: MouseEvent<HTMLDivElement>) => {
            if (!menuElRef.current?.contains(e.target as HTMLElement)) {
              showPreview(file.id);
            }
          }}
          direction="column"
          shadow="sm"
        >
          <Box
            flex="1"
            w="full"
            position="relative"
            overflow="hidden"
            bg="#efefef"
          >
            <Image src={fileUrl} w="full" h="full" objectFit="cover" />

            <Box ref={menuElRef} position="absolute" top="1" right="1">
              {isMenuActive ? (
                <DocFilePreviewMenu
                  docId={docId}
                  file={file}
                  onAction={(action) => {
                    if (action === "download" || action === "print") {
                      setMenuActive(false);
                    }
                  }}
                  desktopShrink
                  actions={[
                    ...(role === UserRole.Admin || role === UserRole.SuperAdmin
                      ? ["delete" as const]
                      : []),
                    "download",
                  ]}
                />
              ) : (
                <Center
                  as="button"
                  ref={cancelRef}
                  w={{ base: "10", lg: "8" }}
                  h={{ base: "10", lg: "8" }}
                  rounded="full"
                  bg="white"
                  onClick={() => setMenuActive(true)}
                >
                  <Box transform="auto" scale={{ base: "1", lg: "0.8" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="3"
                      viewBox="0 0 23 3"
                    >
                      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
                      <circle
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(10)"
                        fill="currentColor"
                      />
                      <circle
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(20)"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                </Center>
              )}
            </Box>
          </Box>

          <Box
            bg="white"
            px="5px"
            py="3px"
            w="full"
            overflow="hidden"
            fontSize={{ base: "xs", lg: "x-small" }}
            fontWeight="medium"
          >
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {file.filename}
            </Text>
            <Text>
              {format(new Date(file.attachedAt), "dd-MM-yyyy | HH:mm")}
            </Text>
          </Box>
        </Flex>
      </AspectRatio>
    </>
  );
};

export default DocFilePreviewItem;
