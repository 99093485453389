import { useEffect, useState } from "react";
import { Button, Checkbox, HStack, VStack } from "@chakra-ui/react";

import CheckboxIcon from "./CheckboxIcon";

export interface ICheckboxListProps<T> {
  options: { label: string; value: T }[];
  values: T[];
  onChange: (values: T[]) => void;
  onClose: () => void;
}

const CheckboxList = <T extends Object>({
  options,
  values: defaultValues,
  onChange,
  onClose,
}: ICheckboxListProps<T>) => {
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  return (
    <>
      <VStack spacing="2" alignItems="stretch">
        {options.map(({ label, value }, i) => {
          const isChecked = values.includes(value);

          return (
            <Checkbox
              key={i}
              icon={<CheckboxIcon />}
              isChecked={isChecked}
              onChange={() => {
                if (isChecked) {
                  setValues(values.filter((v) => v !== value));
                } else {
                  setValues([...values, value]);
                }
              }}
            >
              {label}
            </Checkbox>
          );
        })}
      </VStack>

      <HStack spacing="2" mt="4">
        <Button
          size="sm"
          colorScheme="secondary"
          variant="outline"
          onClick={() => onChange(values)}
        >
          Zapisz
        </Button>
        <Button
          size="sm"
          type="button"
          colorScheme="secondary"
          variant="ghost"
          fontWeight="medium"
          onClick={() => onClose()}
        >
          Anuluj
        </Button>
      </HStack>
    </>
  );
};

export default CheckboxList;
