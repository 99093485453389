import { FC } from "react";

interface IPreviewIconProps {
  zoomOut?: boolean;
}

const PreviewIcon: FC<IPreviewIconProps> = ({ zoomOut }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.207"
    height="28.207"
    viewBox="0 0 28.207 28.207"
  >
    <g>
      <path
        d="M28.5 16.5a12 12 0 1 1-12-12 12 12 0 0 1 12 12z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-4 -4)"
      />
      <path
        d="m31.5 31.5-6.525-6.525"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-4 -4)"
      />
      {!zoomOut && (
        <path
          d="M16.5 12v9"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-4 -4)"
        />
      )}
      <path
        d="M12 16.5h9"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-4 -4)"
      />
    </g>
  </svg>
);

export default PreviewIcon;
