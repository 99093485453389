import { FC, ReactNode } from "react";
import { Box, Center, HStack, StackProps } from "@chakra-ui/react";

// import PreviewIcon from "../../assets/icons/PreviewIcon";

// type TMenuAction = "delete" | "download" | "preview" | "print";
type TMenuAction = "delete" | "download" | "print";

export interface IFileMenuProps extends StackProps {
  actions?: TMenuAction[];
  onAction?: (action: TMenuAction) => void;
  desktopShrink?: boolean;
}

const actionToIcon: Record<TMenuAction, ReactNode> = {
  delete: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        data-name="Icon metro-bin"
        d="M6.037 9.62v15.385a1.543 1.543 0 0 0 1.538 1.538h13.847a1.543 1.543 0 0 0 1.538-1.538V9.62H6.037zm4.615 13.846H9.114V12.7h1.538zm3.077 0h-1.538V12.7h1.539zm3.077 0h-1.538V12.7h1.538zm3.077 0h-1.538V12.7h1.538zM23.345 5h-5V3.082a1.157 1.157 0 0 0-1.154-1.154h-5.385a1.157 1.157 0 0 0-1.154 1.154V5h-5A1.157 1.157 0 0 0 4.5 6.159v1.923h20V6.159A1.157 1.157 0 0 0 23.345 5zm-6.539 0h-4.615V3.486h4.615V5z"
        transform="translate(5.501 5.764)"
        fill="currentColor"
      />
    </svg>
  ),
  download: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g>
        <path
          d="M31.5 22.5v6a3 3 0 0 1-3 3h-21a3 3 0 0 1-3-3v-6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-4 -4)"
        />
        <path
          d="m10.5 15 7.5 7.5 7.5-7.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-4 -4)"
        />
        <path
          d="M18 22.5v-18"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-4 -4)"
        />
      </g>
    </svg>
  ),
  // preview: <PreviewIcon />,
  print: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
    >
      <g data-name="Icon feather-printer">
        <path
          d="M9 13.5V3h18v10.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-2.5 -2.5)"
        />
        <path
          d="M9 27H6a3 3 0 0 1-3-3v-7.5a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3V24a3 3 0 0 1-3 3h-3"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-2.5 -2.5)"
        />
        <path
          d="M9 21h18v12H9z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-2.5 -2.5)"
        />
      </g>
    </svg>
  ),
};

const FileMenu: FC<IFileMenuProps> = ({
  // actions = ["delete", "download", "preview"],
  actions = ["delete", "download"],
  onAction,
  desktopShrink,
  ...rest
}) => (
  <HStack
    spacing={desktopShrink ? { base: "2.5", lg: "1.5" } : "2.5"}
    {...rest}
  >
    {actions.map((action, i) => (
      <Center
        key={i}
        as="button"
        outline="none"
        w={desktopShrink ? { base: "10", lg: "8" } : "10"}
        h={desktopShrink ? { base: "10", lg: "8" } : "10"}
        bg="white"
        boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
        onClick={() => {
          onAction?.(action);
        }}
      >
        <Box
          translateX="0"
          transform="auto"
          scale={desktopShrink ? { base: "1", lg: "0.8" } : undefined}
        >
          {actionToIcon[action]}
        </Box>
      </Center>
    ))}
  </HStack>
);

export default FileMenu;
