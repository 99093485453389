import { FC } from "react";

const PDFIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.429"
    height="25"
    viewBox="0 0 21.429 25"
  >
    <path
      d="M23.051 7.5a3.246 3.246 0 0 1 .67 1.06A3.22 3.22 0 0 1 24 9.793v16.071a1.334 1.334 0 0 1-1.34 1.336H3.91a1.334 1.334 0 0 1-1.339-1.339V3.543A1.334 1.334 0 0 1 3.91 2.2h12.5a3.221 3.221 0 0 1 1.228.279 3.246 3.246 0 0 1 1.06.67zm-6.195-3.4v5.246H22.1a1.524 1.524 0 0 0-.307-.572l-4.365-4.366a1.524 1.524 0 0 0-.572-.307zm5.357 21.317V11.132h-5.8a1.334 1.334 0 0 1-1.339-1.339v-5.8H4.356v21.425h17.858zm-7.171-8.273a10.614 10.614 0 0 0 1.172.781 13.844 13.844 0 0 1 1.632-.1q2.051 0 2.469.684a.674.674 0 0 1 .028.725.04.04 0 0 1-.014.028l-.028.028v.014q-.084.53-.99.53a5.755 5.755 0 0 1-1.6-.279 10.173 10.173 0 0 1-1.814-.739 25.121 25.121 0 0 0-5.469 1.158q-2.134 3.655-3.376 3.655a.811.811 0 0 1-.391-.1l-.335-.167q-.014-.014-.084-.07a.577.577 0 0 1-.084-.5 3.03 3.03 0 0 1 .781-1.277 6.742 6.742 0 0 1 1.842-1.346.2.2 0 0 1 .321.084.08.08 0 0 1 .028.056q.725-1.186 1.493-2.748a21.293 21.293 0 0 0 1.451-3.655 11.277 11.277 0 0 1-.426-2.225 5.424 5.424 0 0 1 .088-1.781q.153-.558.586-.558h.307a.588.588 0 0 1 .488.209 1.114 1.114 0 0 1 .126.949.3.3 0 0 1-.056.112.363.363 0 0 1 .014.112v.419a18.268 18.268 0 0 1-.2 2.679 7.058 7.058 0 0 0 2.037 3.32zm-8.035 5.735a6.16 6.16 0 0 0 1.911-2.2A7.953 7.953 0 0 0 7.7 21.846a4.637 4.637 0 0 0-.693 1.033zm5.553-12.835a4.148 4.148 0 0 0-.028 1.842q.014-.1.1-.614 0-.042.1-.6a.314.314 0 0 1 .056-.112.04.04 0 0 1-.014-.028.028.028 0 0 0-.007-.021.028.028 0 0 1-.007-.021.8.8 0 0 0-.181-.5.04.04 0 0 1-.014.028v.028zm-1.73 9.222a20.463 20.463 0 0 1 3.962-1.13A2.1 2.1 0 0 1 14.61 18a2.5 2.5 0 0 1-.223-.188 7.39 7.39 0 0 1-1.772-2.455 18.643 18.643 0 0 1-1.158 2.748q-.419.781-.628 1.158zm9.012-.223a3.339 3.339 0 0 0-1.953-.335 5.277 5.277 0 0 0 1.73.391 1.353 1.353 0 0 0 .251-.014q0-.014-.028-.042z"
      transform="translate(-2.571 -2.204)"
      fill="currentColor"
    />
  </svg>
);

export default PDFIcon;
