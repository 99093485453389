import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError & { error?: string } {
  return typeof error === "object" && error != null && "status" in error;
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
}

const useErrorMsg = (error: any) => {
  const { t } = useTranslation();

  return error
    ? isFetchBaseQueryError(error)
      ? // @ts-ignore
        error.error || error.data?.detail || JSON.stringify(error.data)
      : isErrorWithMessage(error)
      ? error.message
      : t("errors.default")
    : null;
};

export default useErrorMsg;
