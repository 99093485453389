import { useMemo } from "react";
import { selectAccessToken } from "../redux/auth/authSlice";
import { useAppSelector } from "../redux/store";
import jwtDecode from "jwt-decode";
import { IUser, UserRole } from "../types/user";

const useAccessTokenPayload = () => {
  const accessToken = useAppSelector(selectAccessToken);

  return useMemo(
    () =>
      accessToken
        ? (jwtDecode(accessToken) as {
            name: string;
            username: string;
            roles: UserRole[];
            clients: { id: string; name: string }[];
            constructions: { id: string; name: string }[];
            access?: IUser["access"];
          })
        : null,
    [accessToken]
  );
};

export default useAccessTokenPayload;
