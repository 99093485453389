import { FC } from "react";

const DocIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.75"
    height="25"
    viewBox="0 0 18.75 25"
  >
    <path
      d="M10.938 6.641V0H1.172A1.169 1.169 0 0 0 0 1.172v22.656A1.169 1.169 0 0 0 1.172 25h16.406a1.169 1.169 0 0 0 1.172-1.172V7.812h-6.641a1.175 1.175 0 0 1-1.171-1.171zm7.813-.688v.3H12.5V0h.3a1.171 1.171 0 0 1 .83.342l4.78 4.785a1.168 1.168 0 0 1 .34.825z"
      fill="currentColor"
    />
  </svg>
);

export default DocIcon;
