import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, type SubmitHandler } from "react-hook-form";
import isEmail from "validator/es/lib/isEmail";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import PasswordInputGroup from "../common/PasswordInputGroup";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from "../../redux/api/usersEndpoints";
import useErrorMsg from "../../hooks/useErrorMsg";
import { type IUser, type IUserAccess, UserRole } from "../../types/user";
import CheckboxIcon from "../common/CheckboxIcon";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";

const userAccesses: IUserAccess["type"][] = [
  "invoice",
  "protocol",
  "possession",
];

interface IEditUserFormValues {
  email: string;
  name: string;
  password: string;
  role: UserRole;
  clients: string;
  constructions: string;
  access: IUserAccess[];
  active: string;
}

interface IEditUserFormProps {
  user?: IUser;
  onClose: () => void;
}

const EditUserForm: FC<IEditUserFormProps> = ({ user, onClose }) => {
  const { t } = useTranslation();
  const tokenPayload = useAccessTokenPayload()!;
  const roles = tokenPayload.roles.includes(UserRole.SuperAdmin)
    ? Object.values(UserRole).filter((role) => role !== UserRole.SuperAdmin)
    : Object.values(UserRole).filter(
        (role) => role !== UserRole.SuperAdmin && role !== UserRole.Admin
      );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IEditUserFormValues>({
    defaultValues: user
      ? {
          email: user.email,
          name: user.name,
          password: "",
          role: user.roles[0],
          clients: user.clients.map((client) => client.id).join(","),
          constructions: user.constructions
            .map((construction) => construction.id)
            .join(","),
          active: user.active ? "true" : "false",
          access: userAccesses.map((type) => ({
            type,
            granted:
              user.access?.some((a) => a.type === type && a.granted) || false,
          })),
        }
      : {
          email: "",
          name: "",
          password: "",
          clients: "",
          constructions: "",
          active: "true",
          access: userAccesses.map((type) => ({
            type,
            granted: true,
          })),
        },
  });
  const role = watch("role");
  const access = watch("access");

  const [createUser, createState] = useCreateUserMutation();
  const [updateUser, updateState] = useUpdateUserMutation();

  const isLoading = createState.isLoading || updateState.isLoading;
  const isSuccess = createState.isSuccess || updateState.isSuccess;
  const error = createState.error || updateState.error;
  const errorMsg = useErrorMsg(error);
  const constructionsError = errorMsg?.startsWith("Construction")
    ? errorMsg
    : null;

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit: SubmitHandler<IEditUserFormValues> = ({
    email,
    password,
    clients,
    constructions,
    active,
    access,
    ...values
  }) => {
    const payload = {
      ...values,
      ...(password ? { password } : {}),
      clients: clients
        .split(",")
        .map((id) => id.trim())
        .filter((id) => !!id.trim()),
      constructions: constructions
        .split(",")
        .map((id) => id.trim())
        .filter((id) => !!id),
      active: active === "true",
    };

    if (values.role !== UserRole.Foreman) {
      // @ts-ignore
      payload.email = email;
    }

    // @ts-ignore
    payload.access = role === UserRole.Head ? access : [];

    if (role === UserRole.Foreman) {
      payload.clients = payload.constructions.map((construction) =>
        construction.slice(0, 5)
      );
    }

    if (user) {
      // @ts-ignore
      updateUser({ id: user.id, ...payload });
    } else {
      // @ts-ignore
      createUser(payload);
    }
  };

  return (
    <Box as="form" w="full" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4">
        {errorMsg && !constructionsError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{errorMsg}</AlertTitle>
          </Alert>
        )}

        <FormControl isInvalid={!!errors.role}>
          <FormLabel>{t("common.forms.role.label")}</FormLabel>
          <Select
            size="lg"
            placeholder={t("common.forms.role.placeholder") as string}
            {...register("role", { required: true })}
          >
            {roles.map((role) => (
              <option key={role} value={role}>
                {t(`roles.${role}`)}
              </option>
            ))}
          </Select>
          {errors.role && (
            <FormErrorMessage>
              {t(`common.forms.role.${errors.role.type || "validate"}`)}
            </FormErrorMessage>
          )}
        </FormControl>

        {role && (
          <>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>{t("common.forms.name.label")}</FormLabel>
              <Input
                size="lg"
                placeholder={t("common.forms.name.placeholder") as string}
                {...register("name", {
                  required: true,
                  maxLength: 50,
                })}
              />
              {errors.name && (
                <FormErrorMessage>
                  {t(`common.forms.name.${errors.name.type || "validate"}`)}
                </FormErrorMessage>
              )}
            </FormControl>

            {role !== UserRole.Foreman && (
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>{t("common.forms.email.label")}</FormLabel>
                <Input
                  size="lg"
                  placeholder={t("common.forms.email.placeholder") as string}
                  {...register("email", {
                    required: true,
                    validate: (value) => isEmail(value),
                  })}
                />
                {errors.email && (
                  <FormErrorMessage>
                    {t(`common.forms.email.${errors.email.type || "validate"}`)}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}

            <FormControl isInvalid={!!errors.password}>
              <FormLabel>{t("common.forms.password.label")}</FormLabel>
              <PasswordInputGroup>
                <Input
                  size="lg"
                  placeholder={t("common.forms.password.hint") as string}
                  _placeholder={{
                    fontSize: "sm",
                  }}
                  {...register("password", {
                    required: !user,
                    validate: (value) =>
                      !!user ||
                      (value.length >= 8 &&
                        /\d/.test(value) &&
                        /[a-z]/.test(value) &&
                        /[A-Z]/.test(value)),
                  })}
                />
              </PasswordInputGroup>
              {errors.password && (
                <FormErrorMessage>
                  {t(
                    `common.forms.password.${
                      errors.password.type || "validate"
                    }`
                  )}
                </FormErrorMessage>
              )}
            </FormControl>

            {(role === UserRole.Manager || role === UserRole.Head) && (
              <FormControl isInvalid={!!errors.clients}>
                <FormLabel>{t("common.forms.clients.label")}</FormLabel>
                <Input
                  size="lg"
                  placeholder={t("common.forms.clients.placeholder") as string}
                  {...register("clients", {
                    required: true,
                    validate: (value) => /^(\d{5,5}(,\s*)?)+$/.test(value),
                  })}
                />
                {errors.clients && (
                  <FormErrorMessage>
                    {t(
                      `common.forms.clients.${
                        errors.clients.type || "validate"
                      }`
                    )}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}

            {(role === UserRole.Head || role === UserRole.Foreman) && (
              <FormControl
                isInvalid={!!errors.constructions || constructionsError}
              >
                <FormLabel>{t("common.forms.constructions.label")}</FormLabel>
                <Input
                  size="lg"
                  placeholder={
                    t(
                      `common.forms.constructions.placeholder.${
                        role === UserRole.Foreman ? "single" : "multiple"
                      }`
                    ) as string
                  }
                  onKeyUp={() => {
                    if (constructionsError) {
                      createState.reset();
                      updateState.reset();
                    }
                  }}
                  {...register("constructions", {
                    required: true,
                    ...(role === UserRole.Foreman
                      ? {
                          minLength: 9,
                          maxLength: 9,
                        }
                      : {
                          validate: (value) =>
                            /^(\d{9,9}(,\s*)?)+$/.test(value),
                        }),
                  })}
                />
                {(errors.constructions || constructionsError) && (
                  <FormErrorMessage>
                    {constructionsError ||
                      t(
                        `common.forms.constructions.${
                          errors.constructions?.type || "validate"
                        }`
                      )}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}

            {role === UserRole.Head && (
              <FormControl>
                <FormLabel as="p">{t("common.forms.access.label")}</FormLabel>
                <Wrap spacing="4">
                  {userAccesses.map((type, i) => (
                    <WrapItem key={type}>
                      <Checkbox
                        icon={<CheckboxIcon />}
                        size="lg"
                        isChecked={access[i].granted}
                        onChange={(e) => {
                          setValue(
                            "access",
                            access.map((a, j) => ({
                              ...a,
                              granted: i === j ? e.target.checked : a.granted,
                            }))
                          );
                        }}
                      >
                        {t(`common.forms.access.types.${type}`)}
                      </Checkbox>
                    </WrapItem>
                  ))}
                </Wrap>
              </FormControl>
            )}

            <FormControl isInvalid={!!errors.active}>
              <FormLabel>{t("common.forms.userState.label")}</FormLabel>
              <Select
                size="lg"
                placeholder={t("common.forms.userState.placeholder") as string}
                {...register("active", {
                  required: true,
                })}
              >
                <option value="true">Aktywny</option>
                <option value="false">Nieaktywny</option>
              </Select>
            </FormControl>
          </>
        )}
      </VStack>

      <HStack spacing="2" mt="6">
        <Button
          type="submit"
          colorScheme="secondary"
          variant="outline"
          isLoading={isLoading}
          isDisabled={isSuccess}
        >
          Zapisz
        </Button>
        <Button
          type="button"
          colorScheme="secondary"
          variant="ghost"
          fontWeight="medium"
          onClick={() => onClose()}
        >
          Anuluj
        </Button>
      </HStack>
    </Box>
  );
};

export default EditUserForm;
