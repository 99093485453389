import { FC } from "react";

import useSaveRefreshToken from "../../hooks/useSaveRefreshToken";
import useAutoLogout from "../../hooks/useAutoLogout";
// import useRefreshTokens from "../../hooks/useRefreshTokens";

const AuthProvider: FC = () => {
  useSaveRefreshToken();
  useAutoLogout();
  // useRefreshTokens();

  return null;
};

export default AuthProvider;
