import { Box, Icon, Link, Text } from "@chakra-ui/react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";

import useMenuLinks from "../../hooks/useMenuLinks";

const MobileMenuLinks = () => {
  const { pathname: currentPathname } = useLocation();
  const links = useMenuLinks();

  return (
    <Box display="flex" flexDir="column" alignItems="center" mt={8}>
      {links.map(({ pathame, label, statusIcon: StatusIcon }) => (
        <Link
          as={RouterLink}
          to={pathame}
          key={pathame}
          p={3}
          display="flex"
          alignItems="center"
        >
          <Text
            fontSize="xl"
            fontWeight={pathame === currentPathname ? "bold" : "normal"}
          >
            {label}
          </Text>
          {StatusIcon && <Icon as={StatusIcon} ml="4" w="21px" h="auto" />}
        </Link>
      ))}
    </Box>
  );
};

export default MobileMenuLinks;
