import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  Img,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  VStack,
} from "@chakra-ui/react";

import type { IDocument, IDocumentFile } from "../../types/document";
import useGetDocFileUrl from "../../hooks/useGetDocFileUrl";
import PreviewIcon from "../../assets/icons/PreviewIcon";
import DocFilePreviewMenu from "./DocFilePreviewMenu";
import useDesktopQuery from "../../hooks/useDesktopQuery";
import Album from "../common/Album";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";
import { UserRole } from "../../types/user";

interface IFileViewerModal extends Omit<ModalProps, "children"> {
  docId: IDocument["id"];
  files: IDocumentFile[];
  activeFile: IDocumentFile;
  changeActiveFile: (id: IDocumentFile["id"]) => void;
}

const FileViewerModal: FC<IFileViewerModal> = ({
  docId,
  files,
  activeFile: file,
  changeActiveFile,
  onClose,
  isOpen,
  ...rest
}) => {
  const {
    roles: [role],
  } = useAccessTokenPayload()!;
  const getFileUrl = useGetDocFileUrl();
  const isDesktop = useDesktopQuery();
  const fileUrl = getFileUrl(docId, file.id);
  const fileIndex = files.findIndex((f) => f.id === file.id);
  const [showArrows, setShowArrows] = useState<number | null>(Date.now());

  const [imgSize, setImgSize] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [imgScale, setImgScale] = useState(1);

  const goToPreviousFile = useCallback(() => {
    if (fileIndex > 0) {
      changeActiveFile(files[fileIndex - 1].id);
    } else {
      changeActiveFile(files[files.length - 1].id);
    }
  }, [files, fileIndex, changeActiveFile]);

  const goToNextFile = useCallback(() => {
    if (fileIndex < files.length - 1) {
      changeActiveFile(files[fileIndex + 1].id);
    } else {
      changeActiveFile(files[0].id);
    }
  }, [files, fileIndex, changeActiveFile]);

  useLayoutEffect(() => {
    setImgSize(null);
    setImgScale(1);
    // setShowArrows(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, docId, file.id]);

  // useEffect(() => {
  //   if (showArrows) {
  //     const timeout = setTimeout(() => {
  //       setShowArrows(null);
  //     }, 2000);

  //     return () => {
  //       clearTimeout(timeout);
  //     };
  //   }
  // }, [showArrows]);

  // useEffect(() => {
  //   const moveHandler = () => {
  //     setShowArrows(Date.now());
  //   };

  //   window.addEventListener("mousemove", moveHandler);
  //   return () => {
  //     window.removeEventListener("mousemove", moveHandler);
  //   };
  // }, []);

  useEffect(() => {
    if (!isDesktop && showArrows) {
      const timeout = setTimeout(() => {
        setShowArrows(null);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isDesktop, showArrows]);

  useEffect(() => {
    const keyupHandler = (e: KeyboardEvent) => {
      if (e.code === "ArrowLeft") {
        goToPreviousFile();
      } else if (e.code === "ArrowRight") {
        goToNextFile();
      }
    };

    window.addEventListener("keyup", keyupHandler);
    return () => {
      window.removeEventListener("keyup", keyupHandler);
    };
  }, [goToPreviousFile, goToNextFile]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      // blockScrollOnMount={false}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        // p="0"
        bg="transparent"
        boxShadow="none"
        position="relative"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            onClose();
          }
        }}
      >
        <Box position="absolute" top={{ base: "4", lg: "10" }} right="0">
          <VStack spacing="10" alignItems="flex-end">
            <HStack spacing="10">
              <DocFilePreviewMenu
                docId={docId}
                file={file}
                actions={[
                  "download",
                  "print",
                  ...(role === UserRole.Admin || role === UserRole.SuperAdmin
                    ? ["delete" as const]
                    : []),
                ]}
              />

              <Center
                as="button"
                w="10"
                h="10"
                bg="white"
                boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
                onClick={() => onClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.799"
                  height="19.799"
                  viewBox="0 0 19.799 19.799"
                >
                  <path
                    transform="rotate(45 -.25 .604)"
                    fill="none"
                    stroke="currentColor"
                    d="M0 0h27"
                  />
                  <path
                    transform="rotate(135 9.65 4.204)"
                    fill="none"
                    stroke="currentColor"
                    d="M0 0h27"
                  />
                </svg>
              </Center>
            </HStack>

            {isDesktop && (
              <VStack spacing="2.5">
                <Center
                  as="button"
                  w="10"
                  h="10"
                  bg="white"
                  boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
                  onClick={() => setImgScale(imgScale + 0.15)}
                >
                  <PreviewIcon />
                </Center>
                <Center
                  as="button"
                  w="10"
                  h="10"
                  bg="white"
                  boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
                  onClick={() => setImgScale(imgScale - 0.15)}
                >
                  <PreviewIcon zoomOut />
                </Center>
              </VStack>
            )}
          </VStack>
        </Box>

        <Box mt={{ base: "80px", lg: "144px" }} mx={{ lg: "16" }}>
          {isDesktop ? (
            <Box
              maxW="full"
              maxH="calc(100vh - 204px)"
              w={imgSize?.width ? `max(${imgSize?.width}px, 100%)` : "full"}
              h={imgSize?.height ? `max(${imgSize?.height}px, 100%)` : "full"}
              mx="auto"
              overflow="auto"
            >
              <Image
                onLoad={(e) => {
                  const size = {
                    width: e.currentTarget.naturalWidth,
                    height: e.currentTarget.naturalHeight,
                  };

                  if (size.width > e.currentTarget.parentElement!.clientWidth) {
                    size.width = e.currentTarget.parentElement!.clientWidth;
                    size.height =
                      (e.currentTarget.parentElement!.clientWidth /
                        e.currentTarget.naturalWidth) *
                      e.currentTarget.naturalHeight;
                  }

                  if (
                    size.height > e.currentTarget.parentElement!.clientHeight
                  ) {
                    size.height = e.currentTarget.parentElement!.clientHeight;
                    size.width =
                      (e.currentTarget.parentElement!.clientHeight /
                        e.currentTarget.naturalHeight) *
                      e.currentTarget.naturalWidth;
                  }

                  setImgSize(size);
                }}
                src={fileUrl}
                w={imgSize?.width && imgSize.width * imgScale}
                h={imgSize?.height && imgSize.height * imgScale}
                maxW="none"
                mx="auto"
                // transition="opacity ease-out 300ms"
                opacity={imgSize ? 1 : 0}
              />
            </Box>
          ) : (
            <Box maxW="full" h="calc(100vh - 64px - 2rem)" position="relative">
              <Album
                images={files.map((file) => getFileUrl(docId, file.id))}
                defaultIndex={fileIndex}
                onChangeIndex={(index) => changeActiveFile(files[index].id)}
              />

              <Img
                src="assets/swipe.png"
                srcSet="assets/swipe@2x.png 2x, assets/swipe@3x.png 3x"
                position="absolute"
                left="50%"
                top="50%"
                transform="auto"
                translateX="-50%"
                translateY="-50%"
                zIndex={2}
                transitionProperty="opacity"
                transitionTimingFunction="ease-out"
                transitionDuration="300ms"
                opacity={showArrows ? 1 : 0}
                pointerEvents="none"
              />
            </Box>
          )}
        </Box>

        {isDesktop && (
          <>
            <Center
              as="button"
              w="10"
              h="10"
              bg="white"
              boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
              position="absolute"
              top="50%"
              left="0"
              transform="auto"
              translateY="-100%"
              onClick={() => goToPreviousFile()}
            >
              <Box
                as="svg"
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 24 24"
                transform="auto"
                rotate="90deg"
                translateY="0"
              >
                <path
                  fill="none"
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m3.116274 8.559785 9 9 9-9"
                />
              </Box>
            </Center>
            <Center
              as="button"
              w="10"
              h="10"
              bg="white"
              boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.08)"
              position="absolute"
              top="50%"
              right="0"
              transform="auto"
              translateY="-100%"
              onClick={() => goToNextFile()}
            >
              <Box
                as="svg"
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 24 24"
                transform="auto"
                rotate="-90deg"
                translateY="0"
              >
                <path
                  fill="none"
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m3.116274 8.559785 9 9 9-9"
                />
              </Box>
            </Center>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default FileViewerModal;
