import { FC, SVGProps } from "react";

const PlusIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" {...props}>
    <line x1="0" y1="9" x2="18" y2="9" strokeWidth="1" stroke="currentColor" />
    <line x1="9" y1="0" x2="9" y2="18" strokeWidth="1" stroke="currentColor" />
  </svg>
);

export default PlusIcon;
