import { useSearchParams } from "react-router-dom";
import { PAGE_PARAM_KEY, PER_PAGE_PARAM_KEY } from "../constants/params";
import { PAGINATION_PER_PAGE_COUNTS } from "../constants/pagination";
import useAccessTokenPayload from "./useAccessTokenPayload";
import { UserRole } from "../types/user";

const usePageParams = () => {
  const {
    roles: [role],
  } = useAccessTokenPayload()!;
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get(PAGE_PARAM_KEY)) || 1;
  const setPage = (page: number) => {
    searchParams.set(PAGE_PARAM_KEY, String(page + 1));
    setSearchParams(searchParams);
  };

  const perPage =
    parseInt(searchParams.get(PER_PAGE_PARAM_KEY) as string) ||
    (role === UserRole.SuperAdmin || role === UserRole.Admin
      ? PAGINATION_PER_PAGE_COUNTS[PAGINATION_PER_PAGE_COUNTS.length - 1]
      : PAGINATION_PER_PAGE_COUNTS[0]);
  const setPerPage = (perPage: number | string) => {
    searchParams.set(PER_PAGE_PARAM_KEY, perPage.toString());
    setSearchParams(searchParams);
  };

  return { page, setPage, perPage, setPerPage };
};

export default usePageParams;
