import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import defaultTranslation from "../locales/pl.json";
import { REFRESH_TOKEN_KEY } from "../constants/storage";
import { store } from "../redux/store";
import { refreshTokens, setRefreshToken } from "../redux/auth/authSlice";

const boostrap = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        pl: {
          translation: defaultTranslation,
        },
      },
      lng: "pl",
      fallbackLng: "pl",
      interpolation: {
        escapeValue: true,
      },
    });

  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (refreshToken) {
    store.dispatch(setRefreshToken(refreshToken));
    store.dispatch(refreshTokens(refreshToken));
  }
};

boostrap();
