import { useEffect } from "react";

import { useAppSelector } from "../redux/store";
import { selectRefreshToken } from "../redux/auth/authSlice";
import { REFRESH_TOKEN_KEY } from "../constants/storage";

const useSaveRefreshToken = () => {
  const refreshToken = useAppSelector(selectRefreshToken);

  useEffect(() => {
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    } else {
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  }, [refreshToken]);
};

export default useSaveRefreshToken;
