import { SVGProps } from "react";

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.5"
    height="17"
    viewBox="0 0 15.5 17"
    {...props}
  >
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g>
        <path
          d="m11.576 9-3.834 3.833L6 11.091"
          transform="translate(-.676 .167)"
        />
      </g>
      <g>
        <path
          d="M6.1 6h11.2a1.578 1.578 0 0 1 1.6 1.556v10.888A1.578 1.578 0 0 1 17.3 20H6.1a1.578 1.578 0 0 1-1.6-1.556V7.556A1.578 1.578 0 0 1 6.1 6z"
          transform="translate(-4 -3.5)"
        />
        <path d="M24 3v4" transform="translate(-13.1 -2.5)" />
        <path d="M12 3v4" transform="translate(-7.5 -2.5)" />
        <path d="M4.5 15h14.4" transform="translate(-4 -8.1)" />
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
