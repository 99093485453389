import { FC, useEffect, useRef, useState } from "react";
import { HStack, Text, Button, StackProps } from "@chakra-ui/react";

import PDFIcon from "../../assets/icons/PDFIcon";
import type { IDocument } from "../../types/document";
import { useDeleteDocumentMutation } from "../../redux/api/documentsEndpoints";
import ConfirmDialog from "../common/ConfirmDialog";
import useCanModifyDocs from "../../hooks/useCanModifyDocs";

export interface IMainDocLabelProps extends StackProps {
  doc: IDocument;
}

const MainDocLabel: FC<IMainDocLabelProps> = ({ doc, ...rest }) => {
  const canModifyDocs = useCanModifyDocs();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [deleteDoc, { isLoading, isSuccess, error }] =
    useDeleteDocumentMutation();

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setDeleteDialogOpen(false);
    }
  }, [isSuccess]);

  return (
    <>
      <HStack px="2.5" py="2" spacing="2.5" bg="white" {...rest}>
        <PDFIcon />
        <Text
          flex="1"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {doc.file.filename}
        </Text>
        {canModifyDocs && (
          <Button
            ref={cancelRef}
            variant="ghost"
            colorScheme="secondary"
            onClick={() => setDeleteDialogOpen(true)}
            size="sm"
            isLoading={isLoading}
            isDisabled={isSuccess}
          >
            Usuń
          </Button>
        )}
      </HStack>

      {canModifyDocs && (
        <ConfirmDialog
          leastDestructiveRef={cancelRef}
          isOpen={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title="Usuwanie dokumentu"
          confirmLabel="Usuń dokument"
          onConfirm={() => deleteDoc(doc.id)}
          onCancel={() => setDeleteDialogOpen(false)}
        >
          Czy jesteś pewny, że chcesz usunąć dokument{" "}
          <b>{doc.file.documentNumber || doc.id}</b>?
        </ConfirmDialog>
      )}
    </>
  );
};

export default MainDocLabel;
