import { api } from "./index";
import { TAG_LIST_SYMBOL } from "../../constants/redux";
import type {
  IPaginatedReponse,
  IPaginatedRequestQueryParams,
} from "../../types/api";
import type { IClient } from "../../types/client";

type TGetClientsQueryParams = IPaginatedRequestQueryParams & {
  ["filters[id]"]?: string;
  ["filters[name]"]?: string;
  ["filters[client_id]"]?: string;
};

export const clientsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<
      IPaginatedReponse<IClient>,
      TGetClientsQueryParams
    >({
      query: (params) =>
        `/clients?${new URLSearchParams(
          params as unknown as Record<string, string>
        ).toString()}`,
      providesTags: (result) =>
        result
          ? [
              { id: TAG_LIST_SYMBOL, type: "Client" },
              ...result.data.map((client) => ({
                id: client.id,
                type: "Client" as const,
              })),
            ]
          : [],
    }),
    createClient: builder.mutation<void, Pick<IClient, "id" | "name">>({
      query: (body) => ({
        url: "/clients",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: "Client", id: TAG_LIST_SYMBOL }],
    }),
    getClient: builder.query<IClient, IClient["id"]>({
      query: (id) => `/clients/${id}`,
      providesTags: (client) =>
        client ? [{ type: "Client", id: client.id }] : [],
    }),
    updateClient: builder.mutation<
      void,
      {
        id: IClient["id"];
        client: Partial<Omit<IClient, "createdAt" | "updatedAt">>;
      }
    >({
      query: ({ id, client: body }) => ({
        url: `/clients/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, { id }) =>
        error
          ? []
          : [
              { type: "Client", id: TAG_LIST_SYMBOL },
              { type: "Client", id },
            ],
    }),
    deleteClient: builder.mutation<void, IClient["id"]>({
      query: (id) => ({
        url: `/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        error
          ? []
          : [
              { type: "Client", id: TAG_LIST_SYMBOL },
              { type: "Client", id },
            ],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useCreateClientMutation,
  useGetClientQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientsApiSlice;
