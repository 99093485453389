import { FC, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, HStack, Spinner, Text } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetClientQuery } from "../../redux/api/clientsEndpoints";
import { useGetConstructionsQuery } from "../../redux/api/constructionsEndpoints";
import { ADD_CONSTRUCTION_HASH } from "../../constants/hashes";
import useHash from "../../hooks/useHash";
import Dialog from "../common/Dialog";
import EditConstructionForm from "../common/EditConstructionForm";
import ListingPage from "../layout/ListingPage";
import { IFilterAbcItem } from "../../types/filters";
import Filters from "../common/Filters";
import usePageParams from "../../hooks/usePageParams";
import useSortingParams from "../../hooks/useSortingParams";
import ListingPagination from "../common/ListingPagination";
import { getPageCountFromMeta } from "../../utils/pagination";
import LoadingTable from "../common/table/LoadingTable";
import useFiltersParams from "../../hooks/useFiltersParams";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { IConstruction } from "../../types/construction";
import ConstructionRowActions from "../common/ConstructionRowActions";
import GenericTable from "../common/table/GenericTable";
import GoBackIcon from "../../assets/icons/GoBackIcon";
import useAccessTokenPayload from "../../hooks/useAccessTokenPayload";
import { UserRole } from "../../types/user";

type ConstructionsRestFilters = {
  starts_with: IFilterAbcItem;
};

const columnHelper = createColumnHelper<IConstruction>();

const Constructions: FC = () => {
  const { roles } = useAccessTokenPayload()!;
  const canUserAddObjects =
    roles.includes(UserRole.SuperAdmin) || roles.includes(UserRole.Admin);

  const location = useLocation();
  const navigate = useNavigate();
  const { hash, setHash, removeHash } = useHash();
  const { clientId } = useParams();
  const { data: client, error } = useGetClientQuery(clientId!);
  const isCreateConstructionModalOpen = hash === ADD_CONSTRUCTION_HASH;

  const { page, perPage } = usePageParams();
  const { sortBy, sortOrder, sortingState, setSortingState } =
    useSortingParams();

  useEffect(() => {
    if (client && !client.id) {
      navigate("..");
    } else if (error) {
      console.error(error);
      navigate("..");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id, error]);

  const restFilters = useMemo<ConstructionsRestFilters>(
    () => ({
      starts_with: { type: "abc" },
    }),
    []
  );
  const filters = useMemo(
    () => ({
      ...restFilters,
    }),
    [restFilters]
  );
  const { values: filterValues, onChange: handleFiltersChange } =
    useFiltersParams(filters);

  const { data: getConstructionsData, isFetching } = useGetConstructionsQuery(
    client?.id
      ? {
          page,
          perPage,
          ...(sortBy && sortOrder
            ? {
                sortBy,
                sortOrder,
              }
            : {
                sortBy: "id",
                sortOrder: "desc",
              }),
          "filters[client_id]": client.id,
          ...(filterValues.starts_with
            ? { "filters[starts_with]": filterValues.starts_with }
            : {}),
        }
      : skipToken
  );
  const pageCount = getPageCountFromMeta(getConstructionsData?.meta);

  const columns = useMemo<ColumnDef<IConstruction, any>[]>(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: "id",
        header: () => "ID",
        cell: (info) => <Text>{info.renderValue()}</Text>,
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        header: () => "Nazwa obiektu",
        cell: (info) => <Text as="b">{info.renderValue()}</Text>,
      }),
      columnHelper.accessor((row) => row.id, {
        id: "actions",
        header: () => "",
        cell: ({ row }) => (
          <ConstructionRowActions
            construction={row.original}
            canEdit={canUserAddObjects}
          />
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canUserAddObjects]
  );

  return (
    <>
      {client ? (
        <>
          {/* <Button
            variant="link"
            colorScheme="secondary"
            onClick={() => navigate("/clients")}
          >
            {"< Powrót"}
          </Button> */}

          <ListingPage
            isFetching={!!getConstructionsData && isFetching}
            filters={
              <Filters
                filters={restFilters}
                values={filterValues}
                // @ts-ignore
                onChange={handleFiltersChange}
              />
            }
            actions={
              canUserAddObjects ? (
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    setHash(ADD_CONSTRUCTION_HASH);
                  }}
                >
                  Dodaj obiekt
                </Button>
              ) : undefined
            }
          >
            <HStack
              as={Link}
              to={location.state?.prevLocation || "/clients"}
              spacing="1"
              my="-2.5"
              px="4"
            >
              <GoBackIcon />
              <Text fontWeight="medium">{client.name || client.id}</Text>
            </HStack>
            <Box flex="1" w="full" px={{ base: "0", lg: "4" }}>
              {getConstructionsData?.data ? (
                <GenericTable
                  data={getConstructionsData.data}
                  columns={columns}
                  sortingState={sortingState}
                  setSortingState={setSortingState}
                  emptyStateText="List obiektów jest pusta"
                />
              ) : (
                <LoadingTable />
              )}
            </Box>

            <ListingPagination pageCount={pageCount} />
          </ListingPage>

          {canUserAddObjects && (
            <Dialog
              isOpen={isCreateConstructionModalOpen}
              onClose={() => removeHash()}
              title="Dodaj obiekt"
            >
              <EditConstructionForm
                clientId={client.id}
                onClose={() => removeHash()}
              />
            </Dialog>
          )}
        </>
      ) : (
        <Spinner size="lg" display="block" mx="auto" my="8" />
      )}
    </>
  );
};

export default Constructions;
