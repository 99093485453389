import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import {
  VStack,
  Flex,
  HStack,
  Button,
  Box,
  // Spinner
} from "@chakra-ui/react";

import SearchIcon from "../../assets/icons/SearchIcon";
import useDesktopQuery from "../../hooks/useDesktopQuery";

interface IListingPageProps {
  isFetching?: boolean;
  // title: ReactNode;
  search?: ReactNode;
  filters?: ReactNode;
  actions?: ReactNode;
}

const FiltersIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    viewBox="0 0 20 15"
  >
    <path
      d="M0 0h9.667"
      data-name="Line 56"
      fill="none"
      stroke="currentColor"
      transform="translate(5 14.5)"
    />
    <path
      d="M0 0h14"
      data-name="Line 59"
      fill="none"
      stroke="currentColor"
      transform="translate(3 7.5)"
    />
    <path
      d="M0 0h20"
      data-name="Line 60"
      fill="none"
      stroke="currentColor"
      transform="translate(0 .5)"
    />
  </svg>
);

const PlusIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      d="M0 0h20"
      data-name="Line 56"
      fill="none"
      stroke="currentColor"
      transform="translate(0 10)"
    />
    <path
      d="M0 0h20"
      data-name="Line 57"
      fill="none"
      stroke="currentColor"
      transform="rotate(90 5 5)"
    />
  </svg>
);

const ListingPage: FC<PropsWithChildren<IListingPageProps>> = ({
  // isFetching,
  search,
  filters,
  actions,
  children,
}) => {
  const isDesktop = useDesktopQuery();
  const [activeBar, setActiveBar] = useState<
    "search" | "filters" | "actions" | null
  >(null);

  useEffect(() => {
    if (
      (!search && activeBar === "search") ||
      (!filters && activeBar === "filters") ||
      (!actions && activeBar === "actions")
    ) {
      setActiveBar(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filters, actions]);

  return (
    <VStack
      // maxW="1280"
      spacing="6"
      alignItems="start"
      flex="1"
      overflow="hidden"
    >
      {isDesktop ? (
        <Flex
          w="full"
          align="center"
          justifyContent="space-between"
          flexWrap="wrap"
          px="4"
        >
          <HStack spacing="2.5">
            {/* {isFetching && <Spinner />} */}
            {search}
            {filters}
          </HStack>

          <HStack spacing="2.5">{actions}</HStack>
        </Flex>
      ) : (
        <Box w="full" px="4">
          <Flex align="center" justifyContent="space-between" flexWrap="wrap">
            <HStack spacing="3">
              {search && (
                <Button
                  onClick={() =>
                    setActiveBar(activeBar === "search" ? null : "search")
                  }
                  colorScheme="gray"
                  border={activeBar === "search" ? "1px solid" : undefined}
                  borderColor="secondary.500"
                  rounded="md"
                  leftIcon={<SearchIcon />}
                >
                  Szukaj
                </Button>
              )}
              {filters && (
                <Button
                  onClick={() =>
                    setActiveBar(activeBar === "filters" ? null : "filters")
                  }
                  colorScheme="gray"
                  border={activeBar === "filters" ? "1px solid" : undefined}
                  borderColor="secondary.500"
                  rounded="md"
                  leftIcon={<FiltersIcon />}
                >
                  Filtry
                </Button>
              )}
            </HStack>

            {actions && (
              <Button
                onClick={() =>
                  setActiveBar(activeBar === "actions" ? null : "actions")
                }
                colorScheme="gray"
                border={activeBar === "actions" ? "1px solid" : undefined}
                borderColor="secondary.500"
                rounded="md"
                leftIcon={<PlusIcon />}
              >
                Dodaj
              </Button>
            )}
          </Flex>

          {activeBar && (
            <HStack
              mt="4"
              spacing="2"
              justify={activeBar === "search" ? "center" : undefined}
            >
              {activeBar === "search"
                ? search
                : activeBar === "actions"
                ? actions
                : activeBar === "filters"
                ? filters
                : null}
            </HStack>
          )}
        </Box>
      )}

      {children}
    </VStack>
  );
};

export default ListingPage;
