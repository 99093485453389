import { useCallback } from "react";

import { useAppDispatch } from "../redux/store";
import { logout as logoutAction } from "../redux/auth/authSlice";
import { REFRESH_TOKEN_KEY } from "../constants/storage";
import { useNavigate } from "react-router-dom";
import { api } from "../redux/api";

const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    dispatch(logoutAction());
    dispatch(api.util.resetApiState());

    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return logout;
};

export default useLogout;
