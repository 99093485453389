import { FC, PropsWithChildren, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";

import { useAppSelector } from "../../redux/store";
import {
  selectAccessToken,
  selectRefreshToken,
} from "../../redux/auth/authSlice";

const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const returnUrl = `${location.pathname}${location.search}${location.hash}`;
  const refreshToken = useAppSelector(selectRefreshToken);
  const accessToken = useAppSelector(selectAccessToken);

  if (refreshToken) {
    if (accessToken) {
      return children as ReactElement;
    } else {
      return (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Spinner size="lg" />
        </Box>
      );
    }
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
          search: `?returnUrl=${encodeURIComponent(returnUrl)}`,
        }}
        replace
      />
    );
  }
};

export default ProtectedRoute;
