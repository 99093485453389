import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DashboardIcon from "../assets/icons/DashboardIcon";
import ClientIcon from "../assets/icons/ClientIcon";
import DocsIcon from "../assets/icons/DocsIcon";
import UsersIcon from "../assets/icons/UsersIcon";
import CalendarIcon from "../assets/icons/CalendarIcon";
import MessageIcon from "../assets/icons/MessageIcon";
import useAccessTokenPayload from "./useAccessTokenPayload";
import { UserRole } from "../types/user";
import { useGetMessageQuery } from "../redux/api/messageEndpoints";

const useMenuLinks = () => {
  const { t } = useTranslation();
  const {
    roles: [role],
    clients,
    constructions,
  } = useAccessTokenPayload()!;
  const { data: message } = useGetMessageQuery();

  const links = useMemo(
    () => [
      {
        pathame: "/dashboard",
        label: t("dashboard.links.dashboard"),
        icon: DashboardIcon,
      },
      ...((role === UserRole.Manager && clients.length >= 1) ||
      (role === UserRole.Head &&
        clients.length > 0 &&
        constructions.length > 1) ||
      role === UserRole.SuperAdmin ||
      role === UserRole.Admin ||
      role === UserRole.Employee
        ? [
            {
              pathame: "/clients",
              label: t("dashboard.links.clients"),
              icon: ClientIcon,
            },
          ]
        : []),
      {
        pathame: "/docs",
        label: t("dashboard.links.docs"),
        icon: DocsIcon,
      },
      ...(role === UserRole.SuperAdmin || role === UserRole.Admin
        ? [
            {
              pathame: "/users",
              label: t("dashboard.links.users"),
              icon: UsersIcon,
            },
            {
              pathame: "#message",
              label: t("dashboard.links.message"),
              icon: MessageIcon,
              statusIcon: message ? CalendarIcon : undefined,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, role, clients.length, constructions.length, !!message]
  );

  return links;
};

export default useMenuLinks;
