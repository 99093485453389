import { FC, useMemo } from "react";
import { Box, Button, Text } from "@chakra-ui/react";

import Dialog from "../common/Dialog";
import EditUserForm from "../users/EditUserForm";
import useHash from "../../hooks/useHash";
import { ADD_USER_HASH } from "../../constants/hashes";
import ListingPage from "../layout/ListingPage";
import ListingPagination from "../common/ListingPagination";
import {
  IFilterSearchItem,
  // IFilterSelectItem
} from "../../types/filters";
// import { UserRole } from "../../types/user";
import Filters from "../common/Filters";
import usePageParams from "../../hooks/usePageParams";
import useSortingParams from "../../hooks/useSortingParams";
import { useGetUsersQuery } from "../../redux/api/usersEndpoints";
import { getPageCountFromMeta } from "../../utils/pagination";
import LoadingTable from "../common/table/LoadingTable";
import useFiltersParams from "../../hooks/useFiltersParams";
import { IUser, UserRole } from "../../types/user";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import UserRowActions from "../common/UserRowActions";
import GenericTable from "../common/table/GenericTable";
import { useTranslation } from "react-i18next";

type UsersFilters = {
  // email: IFilterSearchItem;
  name: IFilterSearchItem;
  // role: IFilterSelectItem<UserRole>;
};

const columnHelper = createColumnHelper<IUser>();

const Users: FC = () => {
  const { t } = useTranslation();
  const { hash, removeHash, setHash } = useHash();
  const isAddUserModalOpen = hash === ADD_USER_HASH;

  const filters = useMemo<UsersFilters>(
    () => ({
      // email: { type: "search", placeholder: "Wpisz nr dokumentu" },
      name: { type: "search", placeholder: "Wpisz nazwę użytkownika" },
      // role: {
      //   type: "select",
      //   placeholder: "Wybierz role",
      //   values: [
      //     { label: "Admin", value: UserRole.Admin },
      //     { label: "Employee", value: UserRole.Employee },
      //     { label: "Manager", value: UserRole.Manager },
      //     { label: "Head", value: UserRole.Head },
      //     { label: "Foreman", value: UserRole.Foreman },
      //   ],
      // },
    }),
    []
  );
  const { values: filterValues, onChange: handleFiltersChange } =
    useFiltersParams(filters);

  const { page, perPage } = usePageParams();
  const { sortBy, sortOrder, sortingState, setSortingState } =
    useSortingParams();

  const { data: getUsersData, isFetching } = useGetUsersQuery({
    page,
    perPage,
    ...(sortBy && sortOrder
      ? {
          sortBy,
          sortOrder,
        }
      : {
          sortBy: "user_id",
          sortOrder: "desc",
        }),
    // ...(filterValues.email ? { "filters[email]": filterValues.email } : {}),
    ...(filterValues.name ? { "filters[name]": filterValues.name } : {}),
    // ...(filterValues.role ? { "filters[role]": filterValues.role } : {}),
  });
  const pageCount = getPageCountFromMeta(getUsersData?.meta);

  const columns = useMemo<ColumnDef<IUser, any>[]>(
    () => [
      columnHelper.accessor((row) => row.userId, {
        id: "user_id",
        header: () => "ID",
        cell: (info) => <Text>{info.renderValue()}</Text>,
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        header: () => "Nazwa",
        cell: (info) => <Text as="b">{info.renderValue()}</Text>,
      }),
      columnHelper.accessor((row) => row.email, {
        id: "email",
        header: () => "Login",
        cell: (info) => (
          <Text>
            {info.row.original.roles[0] === UserRole.Foreman
              ? "-"
              : info.renderValue() || "-"}
          </Text>
        ),
      }),
      columnHelper.accessor((row) => row.clients, {
        id: "client_id",
        header: () => "Klient ID",
        cell: (info) => (
          <Text>
            {(info.getValue() as string[])
              ?.map((client) => (client as any).id)
              .join(",") || "-"}
          </Text>
        ),
      }),
      columnHelper.accessor((row) => row.constructions, {
        id: "construction_id",
        header: () => "Obiekt ID",
        cell: (info) => (
          <Text>
            {(info.getValue() as string[])
              ?.map((construction) => (construction as any).id)
              .join(",") || "-"}
          </Text>
        ),
      }),
      columnHelper.accessor((row) => row.roles, {
        id: "role",
        header: () => "Uprawnienia",
        enableSorting: false,
        cell: (info) => (
          <Text>
            {(info.getValue() as UserRole[])
              .map((role) => t(`roles.${role}`))
              .slice(0, -1)
              .join(", ") || "-"}
          </Text>
        ),
      }),
      columnHelper.accessor((row) => row.id, {
        id: "actions",
        header: () => "",
        cell: ({ row }) => <UserRowActions user={row.original} />,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <ListingPage
        isFetching={!!getUsersData && isFetching}
        search={
          <Filters
            filters={filters}
            values={filterValues}
            onChange={handleFiltersChange}
          />
        }
        actions={
          <Button
            colorScheme="gray"
            onClick={() => {
              setHash(ADD_USER_HASH);
            }}
          >
            Dodaj użytkownika
          </Button>
        }
      >
        <Box flex="1" w="full" px={{ base: "0", lg: "4" }}>
          {getUsersData?.data ? (
            <GenericTable
              data={getUsersData.data}
              columns={columns}
              sortingState={sortingState}
              setSortingState={setSortingState}
              emptyStateText="Lista użytkownikow jest pusta"
            />
          ) : (
            <LoadingTable />
          )}
        </Box>

        <ListingPagination pageCount={pageCount} />
      </ListingPage>

      <Dialog
        isOpen={isAddUserModalOpen}
        onClose={() => removeHash()}
        title="Dodaj użytkownika"
      >
        <EditUserForm onClose={() => removeHash()} />
      </Dialog>
    </>
  );
};

export default Users;
