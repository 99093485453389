import { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";

import SidebarLink from "../common/SidebarLink";
import useMenuLinks from "../../hooks/useMenuLinks";
import FooterLinks from "../common/FooterLinks";

const Sidebar: FC = () => {
  const links = useMenuLinks();

  return (
    <Box
      position="fixed"
      top="20"
      left={0}
      bottom={0}
      display={{ base: "none", lg: "flex" }}
      flexDir="column"
      justifyContent="space-between"
      width="16rem"
      px="4"
      py="1.875rem"
    >
      <Flex flexDir="column">
        {links.map((link) => (
          <SidebarLink
            key={link.pathame}
            icon={link.icon}
            statusIcon={link.statusIcon}
            pathname={link.pathame}
            name={link.label}
          />
        ))}
      </Flex>

      <Flex flexDir="column">
        <FooterLinks />
      </Flex>
    </Box>
  );
};

export default Sidebar;
