import { FC, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import { format } from "date-fns";

import useHash from "../../hooks/useHash";
import { SET_MESSAGE_HASH } from "../../constants/hashes";
import Dialog from "../common/Dialog";
import MessageForm from "../message/MessageForm";
import { useGetMessageQuery } from "../../redux/api/messageEndpoints";
import useMessageStorageKey from "../../hooks/useMessageStorageKey";

const MessageDialogProvider: FC = () => {
  const { data: message, isLoading } = useGetMessageQuery();
  const { hash, removeHash } = useHash();
  const [showMessage, setShowMessage] = useState(false);
  const messageStorageKey = useMessageStorageKey();
  const isDialogOpen = hash === SET_MESSAGE_HASH;

  const handleClose = () => removeHash();

  const handleCloseMessage = () => {
    setShowMessage(false);
    localStorage.setItem(
      messageStorageKey,
      `${message!.createdAt}&${format(new Date(), "dd-MM-yyyy")}`
    );
  };

  useEffect(() => {
    if (message) {
      const value = localStorage.getItem(messageStorageKey);

      if (value) {
        const [createdAt, closedAt] = value.split("&");

        setShowMessage(
          message.createdAt !== createdAt ||
            format(new Date(), "dd-MM-yyyy") !== closedAt
        );
      } else {
        setShowMessage(true);
      }
    } else {
      if (showMessage) {
        setShowMessage(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, isDialogOpen]);

  return (
    <>
      <Dialog
        isOpen={isDialogOpen}
        onClose={handleClose}
        title="Komunikat na warstwie"
      >
        <MessageForm
          exisitingMessage={message}
          onClose={handleClose}
          isFetching={isLoading}
        />
      </Dialog>

      {message ? (
        <Dialog isOpen={showMessage} onClose={handleCloseMessage}>
          <Text
            as="h2"
            mt="-12px"
            mb="30px"
            fontWeight="medium"
            fontSize={{ base: "22px", md: "28px" }}
            lineHeight={{ base: "18px", md: "1" }}
          >
            {message.title}
          </Text>
          <Text
            fontSize={{ base: "20px", md: "22px" }}
            lineHeight={{ base: "24px", md: "32px" }}
            whiteSpace="pre-wrap"
          >
            {message.message}
          </Text>
        </Dialog>
      ) : null}
    </>
  );
};

export default MessageDialogProvider;
